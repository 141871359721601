@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("/icons/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'), url("/icons/MaterialIcons-Regular.woff2") format('woff2'), url("/icons/MaterialIcons-Regular.woff") format('woff'), url("/icons/MaterialIcons-Regular.ttf") format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}
@font-face {
  font-family: 'Gotham';
  src: url('../../public/fonts/gotham-medium/font.eot?#iefix') format('embedded-opentype'), url('../../public/fonts/gotham-medium/font.woff') format('woff'), url('../../public/fonts/gotham-medium/font.ttf') format('truetype'), url('../../public/fonts/gotham-medium/font.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'Gotham';
  src: url('../../public/fonts/Gotham-MediumItalic.ttf') format('truetype');
  font-style: italic;
}
@font-face {
  font-family: "Gotham";
  src: url('../../public/fonts/Gotham-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: "Gotham";
  src: url('../../public/fonts/Gotham-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Gotham";
  src: url('../../public/fonts/Gotham-Light.ttf') format('truetype');
  font-weight: lighter;
}
@font-face {
  font-family: "Gotham";
  src: url('../../public/fonts/Gotham-LightItalic.ttf') format('truetype');
  font-weight: lighter;
  font-style: italic;
}
@font-face {
  font-family: "Gotham";
  src: url('../../public/fonts/Gotham-Thin.ttf') format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: "Gotham";
  src: url('../../public/fonts/Gotham-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: url('../../public/fonts/lato/Lato-Regular.ttf') format('truetype');
}
@font-face {
  font-family: "Lato";
  src: url('../../public/fonts/lato/Lato-Italic.ttf') format('truetype');
  font-style: italic;
}
@font-face {
  font-family: "Lato";
  src: url('../../public/fonts/lato/Lato-Black.ttf') format('truetype');
  font-weight: 900;
}
@font-face {
  font-family: "Lato";
  src: url('../../public/fonts/lato/Lato-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Lato";
  src: url('../../public/fonts/lato/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: "Lato";
  src: url('../../public/fonts/lato/Lato-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Lato";
  src: url('../../public/fonts/lato/Lato-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: "Lato";
  src: url('../../public/fonts/lato/Lato-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Lato";
  src: url('../../public/fonts/lato/Lato-Hairline.ttf') format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: "Lato";
  src: url('../../public/fonts/lato/Lato-HairlineItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
a {
  text-decoration: none;
  color: #172429;
}
a:active,
a:hover,
a:link,
a:visited {
  text-decoration: none;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  border: 1px solid #565656;
}
.video-container embed,
.video-container iframe,
.video-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.animate-repeat {
  line-height: 30px;
  list-style: none;
  box-sizing: border-box;
}
.animate-repeat.ng-enter,
.animate-repeat.ng-move,
scroll .animate-repeat.ng-leave {
  transition: all linear 0.5s;
}
.animate-repeat.ng-enter,
.animate-repeat.ng-leave.ng-leave-active,
.animate-repeat.ng-move {
  opacity: 0;
  max-height: 0;
}
.animate-repeat.ng-enter.ng-enter-active,
.animate-repeat.ng-leave,
.animate-repeat.ng-move.ng-move-active {
  opacity: 1;
  max-height: 30px;
}
.displayNone {
  display: none !important;
}
.fa-spinner {
  margin: 35px 40px;
  font-size: 40px;
}
body {
  font-weight: 300;
  width: 100vw;
  height: 100vh;
  margin: 0;
  background: url("/img/background.jpg") center center;
  font-family: 'Lato';
  overflow: hidden;
}
body .start-layer-background {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: url("/img/layer-background.jpg") center center;
}
body .start-layer-background .layer-logo {
  width: 95px;
  margin: auto;
  position: relative;
  top: 1px;
  right: 1px;
}
body .start-layer-text {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
body .start-layer-text .text-container {
  display: table;
  width: 260px;
  margin: auto;
  position: relative;
  top: 70px;
}
body .start-layer-text .text-container span {
  display: block;
  padding: 2px 60px;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Gotham';
  color: #fff;
}
body .start-layer-text .text-container span.text-big {
  padding: 0;
  font-size: 42px;
  font-weight: lighter;
  line-height: 18px;
}
body .start-layer-form {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
body .start-layer-form .form-container {
  display: table;
  margin: auto;
  position: relative;
  top: 350px;
}
body .start-layer-form .form-container img {
  float: left;
  width: 28px;
  margin: 3px 5px;
  cursor: pointer;
  opacity: 0.6;
}
body .start-layer-form .form-container img:hover {
  opacity: 1;
}
body .start-layer-form .form-container input {
  width: 174px;
  height: 32px;
  margin: 0 5px;
  color: #fff;
  background-color: transparent;
  border-radius: 7px;
  border: 1px solid dimgrey;
  text-align: center;
  text-transform: lowercase;
  outline: none;
}
body .start-layer-form .form-container input:focus {
  border: 1px solid #E02B2B;
}
body .start-layer-form .form-container .alerts-container {
  display: block;
  height: 36px;
}
body .start-layer-form .form-container .alerts-container .alert-danger {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  text-align: center;
  font-weight: 500;
}
body .start-layer-form .form-container .alerts-container .alert-danger .close {
  top: unset;
  right: unset;
}
body .start-layer-form .choose-container {
  display: table;
  margin: auto;
  position: relative;
  top: 330px;
}
body .start-layer-form .choose-container .button {
  float: left;
  padding: 5px 20px;
  font-size: 14px;
  color: #fff;
  border-radius: 6px;
}
body .start-layer-form .choose-container .button.enter {
  float: right;
}
body .start-layer-form .choose-container .buttons-separator {
  margin: 0 10px;
  font-size: 32px;
  line-height: 26px;
}
body .events-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: table;
  z-index: 1;
}
body .events-container .event {
  position: relative;
  display: table;
  padding: 10px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 12px 2px #99a0a6;
  border-radius: 3px;
}
body .events-container .event .context-icon {
  float: left;
}
body .events-container .event .event-content {
  cursor: pointer;
}
body .events-container .event .event-content:hover {
  color: #172429;
}
body .events-container .event .close {
  position: initial;
  font-size: 40px;
}
body .conversations-panel {
  position: fixed;
  bottom: 5px;
  right: 5px;
  display: table;
  z-index: 10;
}
body .conversations-panel .conversation-item {
  float: right;
  width: 280px;
  margin-left: 5px;
  background-color: #fff;
  box-shadow: 0 0 12px 2px #99a0a6;
  border-radius: 3px;
}
body .conversations-panel .conversation-item .conversation-header {
  height: 40px;
  background: #172429;
}
body .conversations-panel .conversation-item .conversation-header .topic {
  width: 200px;
  float: left;
  margin: 0 5px;
  color: #fff;
  font-size: 16px;
  line-height: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
body .conversations-panel .conversation-item .conversation-header .icon-close {
  float: right;
  color: #fff;
  font-size: 30px;
  margin: 5px;
  cursor: pointer;
}
body .conversations-panel .conversation-item .conversation-body {
  width: 100%;
  height: 300px;
  padding: 0 5px;
  overflow-y: auto;
  background: #fff;
}
body .conversations-panel .conversation-item .conversation-body .message-empty {
  margin: 10px 0;
  color: #E3E3E3;
  font-size: 18px;
  text-align: center;
}
body .conversations-panel .conversation-item .conversation-body .message {
  display: table;
  width: 100%;
  margin: 0;
  text-align: left;
}
body .conversations-panel .conversation-item .conversation-body .message .content {
  display: inline-table;
  width: 175px;
  border-radius: 5px 5px 2px 0;
  background-color: #94B32F;
  color: #fff;
  font-size: 16px;
  text-align: left;
  margin-top: 10px;
  padding: 10px;
}
body .conversations-panel .conversation-item .conversation-body .message .date {
  text-align: left;
  font-size: 12px;
  margin: 0 45px;
}
body .conversations-panel .conversation-item .conversation-body .message .user-avatar {
  float: left;
}
body .conversations-panel .conversation-item .conversation-body .message.my-message {
  text-align: right;
}
body .conversations-panel .conversation-item .conversation-body .message.my-message .content {
  background-color: #0098D0;
  text-align: right;
}
body .conversations-panel .conversation-item .conversation-body .message.my-message .date {
  text-align: right;
}
body .conversations-panel .conversation-item .conversation-body .message.my-message .user-avatar {
  float: right;
}
body .conversations-panel .conversation-item .conversation-body .message.typing {
  padding: 10px;
}
body .conversations-panel .conversation-item .conversation-footer {
  font-size: 18px;
  text-align: center;
  border-top: 1px solid #d9d9d9;
}
body .conversations-panel .conversation-item .conversation-footer input {
  height: 40px;
  margin: 0;
  border: none;
  border-radius: 0;
}
body .conversations-panel .conversation-item .conversation-footer button {
  width: 70px;
  height: 40px;
  color: #fff;
  background-color: #0098D0;
  font-size: 18px;
}
body .cookie-bar {
  position: fixed;
  bottom: 60px;
  display: table;
  right: 1vw;
  width: 15vw;
  min-width: 220px;
  text-align: center;
  padding: 5px 1px;
  background-color: #fff;
  box-shadow: 0 0 12px 2px #99a0a6;
  border-radius: 3px;
}
body .cookie-bar .message {
  padding: 5px;
  font-size: 16px;
}
body .cookie-bar .buttons {
  width: 100%;
}
body .cookie-bar .buttons .button {
  float: left;
  width: 7vw;
  margin: 0 3px;
  padding: 3px;
  font-size: 16px;
  color: #172429;
  border-radius: 3px;
}
body .cookie-bar .buttons .button:hover {
  color: #fff;
}
body .cookie-bar .buttons .button.more-info {
  float: right;
  border: 2px solid #E3E3E3;
  text-decoration: initial;
}
body .cookie-bar .buttons .button.more-info:hover {
  border-color: #0098D0;
  background-color: #fff;
  color: #172429;
}
@keyframes sk-bouncedelay {
  0%,
  100%,
  80% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.shadow-box {
  background-color: #fff;
  box-shadow: 0 0 12px 2px #99a0a6;
  border-radius: 3px;
}
.button {
  background-color: transparent;
  border: 1px solid #565656;
  border-radius: 3px;
  font-size: 20px;
  text-align: center;
  color: #172429;
  cursor: pointer;
  opacity: 0.6;
}
.button:hover {
  opacity: 1;
}
.button.toggler {
  border-radius: 20px;
  width: 20px;
  height: 20px;
  margin: 10px auto;
}
.button.toggler.on {
  color: #fff;
  background-color: #172429;
}
.button.category {
  width: 120px;
  height: 80px;
  float: left;
  margin: 0 7px 7px 0;
  padding: 8px 10px;
  cursor: default;
}
.button.category .category-name {
  margin: 10px 0;
  font-size: 12px;
  line-height: 10px;
}
.button.category .category-buttons-container {
  display: table;
  margin: 0 auto;
}
.button.category .category-button {
  float: right;
  cursor: pointer;
  margin: 0 5px;
}
.button.category .category-button .fa {
  font-size: 26px;
}
.button.category.remove {
  float: left;
}
.button.category.remove:hover {
  border: 1px solid #E02B2B;
  color: #E02B2B;
}
.button.category.remove .category-button {
  float: none;
  width: unset;
}
.form-item {
  display: table;
  padding: 5px 0;
  border-bottom: 1px solid #E3E3E3;
}
.form-item .label,
.form-item label {
  float: left;
  padding: 0 5px;
  font-size: 16px;
  font-weight: 300;
  color: #172429;
  line-height: 35px;
}
.form-item .value {
  float: right;
  width: 70%;
  font-size: 22px;
  line-height: 16px;
  padding-top: 22px;
  text-align: center;
  text-decoration: none;
}
.form-item input {
  float: right;
  width: 70%;
  text-align: center;
}
.form-item input.number-spinner {
  width: 120px;
}
.form-item textarea {
  float: right;
  width: 70%;
  height: 100px;
  font-size: 16px;
}
.form-item select {
  float: right;
  width: 200px;
}
.thumbnail {
  float: left;
  margin: 2px 1px;
  padding: 0;
  border: none;
  opacity: 0.7;
  border: 2px solid #E3E3E3;
  cursor: pointer;
}
.thumbnail img {
  height: 100px;
  border-radius: 3px;
}
.thumbnail:hover {
  opacity: 1;
}
.thumbnail.current {
  opacity: 1;
  border: 2px solid #0098D0;
}
.main-container {
  display: block;
  width: 100vw;
}
.main-container .page-header {
  height: 50px;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 50px;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
}
.main-container .page-header .path {
  text-align: center;
  font-size: 15px;
  line-height: 30px;
}
.main-container .page-header .path .item {
  float: left;
  cursor: pointer;
  line-height: 28px;
}
.main-container .page-header .path .item:hover {
  color: #0098D0;
}
.main-container .page-header .path .separator {
  float: left;
  margin: 0 10px;
}
.main-container .page-header .path .separator .material-icons {
  font-size: 30px;
}
.main-container .page-header .category-proposal {
  float: right;
  margin-top: -5px;
  font-size: 14px;
  line-height: 34px;
}
.main-container .page-header .category-proposal .button {
  float: right;
  margin: 0 0 5px 5px;
  padding: 0 5px;
  font-size: 18px;
  line-height: 30px;
}
.main-container .page-header .button {
  height: 40px;
  float: right;
  padding: 2px 8px;
  color: #fff;
}
.main-container .page-header .button span {
  float: left;
  font-size: 16px;
  line-height: 36px;
  font-weight: 100;
  text-transform: uppercase;
}
.main-container .page-header .button .material-icons {
  float: right;
  font-size: 30px;
  line-height: 36px;
  margin-left: 3px;
}
.main-container .filter-panel {
  display: table;
  width: 100%;
  min-height: 80px;
  margin-bottom: 10px;
  background-color: #fff;
}
.main-container .filter-panel .filter-container {
  float: left;
  display: table;
  min-width: 100px;
  margin: 10px;
}
.main-container .filter-panel .filter-container .header {
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  font-size: 20px;
  text-align: center;
}
.main-container .filter-panel .filter-container input {
  margin-top: 5px;
  width: 200px;
}
.main-container .filter-panel .filter-container .button {
  font-size: 18px;
  margin: 5px 0 0;
  padding: 3px 7px;
}
.main-container .filter-panel .filter-container .btn-group {
  margin-top: 5px;
}
.main-container .filter-panel .filter-container .btn-group .btn {
  line-height: 26px;
}
.main-container .categories-section {
  position: fixed;
  width: 105px;
  top: 50px;
  height: calc(100vh - 50px);
  left: 0;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  border-color: #565656;
  border-style: solid;
  border-width: 0 1px 1px 0;
}
.main-container .categories-section .header {
  padding: 5px 0;
  text-align: center;
  font-size: 20px;
  color: #2EB3DE;
}
.main-container .categories-section .list {
  width: 100%;
}
.main-container .categories-section .list .item {
  display: table;
  width: 100%;
  margin: 0 0 5px;
  padding: 10px 0;
  font-size: 14px;
  line-height: 35px;
  border: 1px solid #565656;
  border-radius: 5px;
  color: #d9d9d9;
  cursor: pointer;
  opacity: 0.6;
}
.main-container .categories-section .list .item:hover {
  opacity: 1;
}
.main-container .categories-section .list .item .title {
  margin-top: 3px;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 12px;
  font-weight: 800;
}
.main-container .categories-section .list .item .counter {
  float: right;
}
.main-container .categories-section .list .item .icon {
  margin-top: 10px;
  text-align: center;
}
.main-container .categories-section .list .item .icon img {
  height: 42px;
}
.main-container .preview-section {
  position: fixed;
  width: 15vw;
  min-width: 220px;
  top: 70px;
  right: 1vw;
}
.main-container .preview-section .add-item-container {
  display: table;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 0 12px 2px #99a0a6;
  border-radius: 3px;
}
.main-container .preview-section .add-item-container .only-moment {
  font-size: 14px;
  font-weight: 600;
}
.main-container .preview-section .add-item-container .add-item-button {
  display: table;
  background-color: #FF9900;
  border-radius: 3px;
  color: #fff;
  padding: 5px 10px;
  line-height: 24px;
  margin: 20px auto;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  border: 2px solid transparent;
}
.main-container .preview-section .add-item-container .add-item-button:hover {
  background-color: #fff;
  border-color: #FF9900;
  color: #FF9900;
}
.main-container .preview-section .add-item-container .add-item-button .material-icons {
  float: left;
}
.main-container .preview-section .add-item-container .green-text {
  float: left;
  color: #94B32F;
}
.modal-dialog {
  margin: 10px auto;
}
.modal-dialog .modal-header .close-modal {
  float: right;
  cursor: pointer;
  opacity: 0.7;
}
.modal-dialog .modal-header .close-modal.gallery-close-button {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 30px;
}
.modal-dialog .modal-header .close-modal:hover {
  opacity: 1;
}
.modal-dialog.modal-item-gallery {
  width: 90vw;
}
.modal-dialog.modal-item-gallery .modal-footer,
.modal-dialog.modal-item-gallery .modal-header {
  padding: 0;
}
.modal-dialog.modal-item-gallery .modal-footer .thumbnail-container,
.modal-dialog.modal-item-gallery .modal-header .thumbnail-container {
  display: table;
  margin: 0 auto;
}
.modal-dialog.modal-item-gallery .modal-body {
  padding: 0;
}
.modal-dialog.modal-item-gallery .modal-body .image-container .image {
  display: table;
  margin: 0 auto;
}
.modal-dialog.modal-item-gallery .modal-body .image-container .image img {
  width: 100%;
  border-radius: 4px;
  padding: 2px;
}
.modal-dialog.modal-category-proposal {
  width: 60vw;
}
.modal-dialog.modal-category-proposal .modal-header {
  font-size: 18px;
}
.modal-dialog.modal-category-proposal .modal-body {
  text-align: center;
}
.modal-dialog.modal-category-proposal .modal-body .category-container {
  display: table;
}
.modal-dialog.modal-category-proposal .modal-body .category-container .alert {
  margin-bottom: 10px;
  text-align: l90vweft;
}
.modal-dialog.modal-category-proposal .modal-body .category-container .selected-list {
  display: table;
}
.modal-dialog.modal-category-proposal .modal-body .category-container .button {
  min-width: initial;
  padding: 3px 10px;
  font-size: 14px;
  line-height: 22px;
}
.modal-dialog.modal-category-proposal .modal-body .form-item {
  width: 100%;
  margin: 0 0 10px;
}
.modal-dialog.modal-category-proposal .modal-body .add-proposal {
  font-size: 40px;
  cursor: pointer;
}
.modal-dialog.modal-category-proposal .modal-footer .proposal-list .category {
  display: table;
  width: 100%;
  margin: 0;
}
.modal-dialog.modal-category-proposal .modal-footer .proposal-list .category .arrow-right,
.modal-dialog.modal-category-proposal .modal-footer .proposal-list .category .parent-category,
.modal-dialog.modal-category-proposal .modal-footer .proposal-list .category .proposal-category {
  float: left;
  font-size: 18px;
  line-height: 40px;
}
.modal-dialog.modal-category-proposal .modal-footer .proposal-list .category .proposal-category {
  color: #94B32F;
  font-weight: 400;
}
.modal-dialog.modal-category-proposal .modal-footer .proposal-list .category .arrow-right {
  float: left;
  font-size: 40px;
}
.modal-dialog.modal-category-proposal .modal-footer .proposal-list .category .remove-icon {
  float: right;
  font-size: 40px;
  cursor: pointer;
}
.modal-dialog.modal-category-proposal .modal-footer .message-empty {
  color: #E3E3E3;
  font-size: 18px;
  text-align: center;
}
.modal-dialog.modal-category-proposal .modal-footer .send-proposal {
  float: right;
  margin: 10px 0 0;
  padding: 2px 10px;
  font-size: 22px;
}
.modal-dialog.modal-category-proposal .modal-footer .send-proposal .send-icon {
  float: right;
  margin: 0 0 0 3px;
  font-size: 32px;
}
.modal-dialog.modal-user-login .modal-header {
  font-size: 18px;
  text-align: center;
}
.modal-dialog.modal-user-login .modal-body input {
  display: block;
  width: 250px;
  height: 60px;
  margin: 10px auto;
  font-size: 18px;
  text-align: center;
}
.modal-dialog.modal-user-login .modal-body .terms-section {
  display: table;
  width: 250px;
  margin: 0 auto;
}
.modal-dialog.modal-user-login .modal-body .terms-section .label {
  display: table;
  float: left;
  padding: 0;
  margin: 5px 0;
}
.modal-dialog.modal-user-login .modal-body .terms-section .label .terms-button {
  font-weight: 300;
  font-size: 20px;
  padding: 10px;
}
.modal-dialog.modal-user-login .modal-body .terms-section input {
  float: right;
  display: table;
  width: 30px;
  height: 30px;
  margin: 10px 0;
  padding: 0;
}
.modal-dialog.modal-user-login .modal-footer {
  font-size: 18px;
  text-align: center;
}
.modal-dialog.modal-user-register .modal-header {
  font-size: 18px;
  text-align: center;
}
.modal-dialog.modal-user-register .modal-body input {
  display: block;
  width: 250px;
  height: 60px;
  margin: 10px auto;
  font-size: 18px;
  text-align: center;
}
.modal-dialog.modal-user-register .modal-body .terms-section {
  display: table;
  width: 250px;
  margin: 0 auto;
}
.modal-dialog.modal-user-register .modal-body .terms-section .label {
  display: table;
  float: left;
  padding: 0;
  margin: 5px 0;
}
.modal-dialog.modal-user-register .modal-body .terms-section .label .terms-button {
  font-weight: 300;
  font-size: 20px;
  padding: 10px;
}
.modal-dialog.modal-user-register .modal-body .terms-section input {
  float: right;
  display: table;
  width: 30px;
  height: 30px;
  margin: 10px 0;
  padding: 0;
}
.modal-dialog.modal-user-register .modal-footer {
  font-size: 18px;
  text-align: center;
}
.modal-dialog.modal-client-edit .modal-header,
.modal-dialog.modal-client-new .modal-header,
.modal-dialog.modal-skill-edit .modal-header,
.modal-dialog.modal-skill-module-edit .modal-header,
.modal-dialog.modal-skill-module-new .modal-header,
.modal-dialog.modal-skill-new .modal-header,
.modal-dialog.modal-skill-submodule-edit .modal-header,
.modal-dialog.modal-skill-submodule-new .modal-header,
.modal-dialog.modal-user-change-password .modal-header,
.modal-dialog.modal-user-edit .modal-header {
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal-dialog.modal-client-edit .modal-body,
.modal-dialog.modal-client-new .modal-body,
.modal-dialog.modal-skill-edit .modal-body,
.modal-dialog.modal-skill-module-edit .modal-body,
.modal-dialog.modal-skill-module-new .modal-body,
.modal-dialog.modal-skill-new .modal-body,
.modal-dialog.modal-skill-submodule-edit .modal-body,
.modal-dialog.modal-skill-submodule-new .modal-body,
.modal-dialog.modal-user-change-password .modal-body,
.modal-dialog.modal-user-edit .modal-body {
  display: table;
  width: 100%;
}
.modal-dialog.modal-client-edit .modal-body input,
.modal-dialog.modal-client-new .modal-body input,
.modal-dialog.modal-skill-edit .modal-body input,
.modal-dialog.modal-skill-module-edit .modal-body input,
.modal-dialog.modal-skill-module-new .modal-body input,
.modal-dialog.modal-skill-new .modal-body input,
.modal-dialog.modal-skill-submodule-edit .modal-body input,
.modal-dialog.modal-skill-submodule-new .modal-body input,
.modal-dialog.modal-user-change-password .modal-body input,
.modal-dialog.modal-user-edit .modal-body input,
.modal-dialog.modal-client-edit .modal-body select,
.modal-dialog.modal-client-new .modal-body select,
.modal-dialog.modal-skill-edit .modal-body select,
.modal-dialog.modal-skill-module-edit .modal-body select,
.modal-dialog.modal-skill-module-new .modal-body select,
.modal-dialog.modal-skill-new .modal-body select,
.modal-dialog.modal-skill-submodule-edit .modal-body select,
.modal-dialog.modal-skill-submodule-new .modal-body select,
.modal-dialog.modal-user-change-password .modal-body select,
.modal-dialog.modal-user-edit .modal-body select {
  display: block;
  width: 250px;
  height: 40px;
  margin: 6px auto;
  font-size: 18px;
  text-align: center;
}
.modal-dialog.modal-client-edit .modal-body .checkbox-container,
.modal-dialog.modal-client-new .modal-body .checkbox-container,
.modal-dialog.modal-skill-edit .modal-body .checkbox-container,
.modal-dialog.modal-skill-module-edit .modal-body .checkbox-container,
.modal-dialog.modal-skill-module-new .modal-body .checkbox-container,
.modal-dialog.modal-skill-new .modal-body .checkbox-container,
.modal-dialog.modal-skill-submodule-edit .modal-body .checkbox-container,
.modal-dialog.modal-skill-submodule-new .modal-body .checkbox-container,
.modal-dialog.modal-user-change-password .modal-body .checkbox-container,
.modal-dialog.modal-user-edit .modal-body .checkbox-container {
  display: table;
  width: 250px;
  margin: 0 auto;
}
.modal-dialog.modal-client-edit .modal-body .checkbox-container label,
.modal-dialog.modal-client-new .modal-body .checkbox-container label,
.modal-dialog.modal-skill-edit .modal-body .checkbox-container label,
.modal-dialog.modal-skill-module-edit .modal-body .checkbox-container label,
.modal-dialog.modal-skill-module-new .modal-body .checkbox-container label,
.modal-dialog.modal-skill-new .modal-body .checkbox-container label,
.modal-dialog.modal-skill-submodule-edit .modal-body .checkbox-container label,
.modal-dialog.modal-skill-submodule-new .modal-body .checkbox-container label,
.modal-dialog.modal-user-change-password .modal-body .checkbox-container label,
.modal-dialog.modal-user-edit .modal-body .checkbox-container label {
  float: left;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 40px;
  font-weight: 100;
  cursor: pointer;
}
.modal-dialog.modal-client-edit .modal-body .checkbox-container input,
.modal-dialog.modal-client-new .modal-body .checkbox-container input,
.modal-dialog.modal-skill-edit .modal-body .checkbox-container input,
.modal-dialog.modal-skill-module-edit .modal-body .checkbox-container input,
.modal-dialog.modal-skill-module-new .modal-body .checkbox-container input,
.modal-dialog.modal-skill-new .modal-body .checkbox-container input,
.modal-dialog.modal-skill-submodule-edit .modal-body .checkbox-container input,
.modal-dialog.modal-skill-submodule-new .modal-body .checkbox-container input,
.modal-dialog.modal-user-change-password .modal-body .checkbox-container input,
.modal-dialog.modal-user-edit .modal-body .checkbox-container input {
  float: right;
  width: 26px;
  height: 26px;
}
.modal-dialog.modal-client-edit .modal-body .uploader-container,
.modal-dialog.modal-client-new .modal-body .uploader-container,
.modal-dialog.modal-skill-edit .modal-body .uploader-container,
.modal-dialog.modal-skill-module-edit .modal-body .uploader-container,
.modal-dialog.modal-skill-module-new .modal-body .uploader-container,
.modal-dialog.modal-skill-new .modal-body .uploader-container,
.modal-dialog.modal-skill-submodule-edit .modal-body .uploader-container,
.modal-dialog.modal-skill-submodule-new .modal-body .uploader-container,
.modal-dialog.modal-user-change-password .modal-body .uploader-container,
.modal-dialog.modal-user-edit .modal-body .uploader-container {
  display: table;
  width: 250px;
  margin: 0 auto;
}
.modal-dialog.modal-client-edit .modal-body .uploader-container .button,
.modal-dialog.modal-client-new .modal-body .uploader-container .button,
.modal-dialog.modal-skill-edit .modal-body .uploader-container .button,
.modal-dialog.modal-skill-module-edit .modal-body .uploader-container .button,
.modal-dialog.modal-skill-module-new .modal-body .uploader-container .button,
.modal-dialog.modal-skill-new .modal-body .uploader-container .button,
.modal-dialog.modal-skill-submodule-edit .modal-body .uploader-container .button,
.modal-dialog.modal-skill-submodule-new .modal-body .uploader-container .button,
.modal-dialog.modal-user-change-password .modal-body .uploader-container .button,
.modal-dialog.modal-user-edit .modal-body .uploader-container .button {
  float: right;
  height: 60px;
  width: 100%;
}
.modal-dialog.modal-client-edit .modal-body .uploader-container .button .material-icons,
.modal-dialog.modal-client-new .modal-body .uploader-container .button .material-icons,
.modal-dialog.modal-skill-edit .modal-body .uploader-container .button .material-icons,
.modal-dialog.modal-skill-module-edit .modal-body .uploader-container .button .material-icons,
.modal-dialog.modal-skill-module-new .modal-body .uploader-container .button .material-icons,
.modal-dialog.modal-skill-new .modal-body .uploader-container .button .material-icons,
.modal-dialog.modal-skill-submodule-edit .modal-body .uploader-container .button .material-icons,
.modal-dialog.modal-skill-submodule-new .modal-body .uploader-container .button .material-icons,
.modal-dialog.modal-user-change-password .modal-body .uploader-container .button .material-icons,
.modal-dialog.modal-user-edit .modal-body .uploader-container .button .material-icons {
  font-size: 40px;
  line-height: 60px;
}
.modal-dialog.modal-client-edit .modal-body .uploader-container .thumnail,
.modal-dialog.modal-client-new .modal-body .uploader-container .thumnail,
.modal-dialog.modal-skill-edit .modal-body .uploader-container .thumnail,
.modal-dialog.modal-skill-module-edit .modal-body .uploader-container .thumnail,
.modal-dialog.modal-skill-module-new .modal-body .uploader-container .thumnail,
.modal-dialog.modal-skill-new .modal-body .uploader-container .thumnail,
.modal-dialog.modal-skill-submodule-edit .modal-body .uploader-container .thumnail,
.modal-dialog.modal-skill-submodule-new .modal-body .uploader-container .thumnail,
.modal-dialog.modal-user-change-password .modal-body .uploader-container .thumnail,
.modal-dialog.modal-user-edit .modal-body .uploader-container .thumnail {
  width: 160px;
  height: 60px;
}
.modal-dialog.modal-client-edit .modal-body .thumbnail-container,
.modal-dialog.modal-client-new .modal-body .thumbnail-container,
.modal-dialog.modal-skill-edit .modal-body .thumbnail-container,
.modal-dialog.modal-skill-module-edit .modal-body .thumbnail-container,
.modal-dialog.modal-skill-module-new .modal-body .thumbnail-container,
.modal-dialog.modal-skill-new .modal-body .thumbnail-container,
.modal-dialog.modal-skill-submodule-edit .modal-body .thumbnail-container,
.modal-dialog.modal-skill-submodule-new .modal-body .thumbnail-container,
.modal-dialog.modal-user-change-password .modal-body .thumbnail-container,
.modal-dialog.modal-user-edit .modal-body .thumbnail-container {
  display: table;
  width: 250px;
  margin: 0 auto;
}
.modal-dialog.modal-client-edit .modal-body .thumbnail-container img,
.modal-dialog.modal-client-new .modal-body .thumbnail-container img,
.modal-dialog.modal-skill-edit .modal-body .thumbnail-container img,
.modal-dialog.modal-skill-module-edit .modal-body .thumbnail-container img,
.modal-dialog.modal-skill-module-new .modal-body .thumbnail-container img,
.modal-dialog.modal-skill-new .modal-body .thumbnail-container img,
.modal-dialog.modal-skill-submodule-edit .modal-body .thumbnail-container img,
.modal-dialog.modal-skill-submodule-new .modal-body .thumbnail-container img,
.modal-dialog.modal-user-change-password .modal-body .thumbnail-container img,
.modal-dialog.modal-user-edit .modal-body .thumbnail-container img {
  width: 250px;
}
.modal-dialog.modal-client-edit .modal-body .thumbnail-container .action-layer,
.modal-dialog.modal-client-new .modal-body .thumbnail-container .action-layer,
.modal-dialog.modal-skill-edit .modal-body .thumbnail-container .action-layer,
.modal-dialog.modal-skill-module-edit .modal-body .thumbnail-container .action-layer,
.modal-dialog.modal-skill-module-new .modal-body .thumbnail-container .action-layer,
.modal-dialog.modal-skill-new .modal-body .thumbnail-container .action-layer,
.modal-dialog.modal-skill-submodule-edit .modal-body .thumbnail-container .action-layer,
.modal-dialog.modal-skill-submodule-new .modal-body .thumbnail-container .action-layer,
.modal-dialog.modal-user-change-password .modal-body .thumbnail-container .action-layer,
.modal-dialog.modal-user-edit .modal-body .thumbnail-container .action-layer {
  position: absolute;
  margin: 5px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 20px;
}
.modal-dialog.modal-client-edit .modal-body .thumbnail-container .action-layer:hover,
.modal-dialog.modal-client-new .modal-body .thumbnail-container .action-layer:hover,
.modal-dialog.modal-skill-edit .modal-body .thumbnail-container .action-layer:hover,
.modal-dialog.modal-skill-module-edit .modal-body .thumbnail-container .action-layer:hover,
.modal-dialog.modal-skill-module-new .modal-body .thumbnail-container .action-layer:hover,
.modal-dialog.modal-skill-new .modal-body .thumbnail-container .action-layer:hover,
.modal-dialog.modal-skill-submodule-edit .modal-body .thumbnail-container .action-layer:hover,
.modal-dialog.modal-skill-submodule-new .modal-body .thumbnail-container .action-layer:hover,
.modal-dialog.modal-user-change-password .modal-body .thumbnail-container .action-layer:hover,
.modal-dialog.modal-user-edit .modal-body .thumbnail-container .action-layer:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
.modal-dialog.modal-client-edit .modal-body .thumbnail-container .action-layer .material-icons,
.modal-dialog.modal-client-new .modal-body .thumbnail-container .action-layer .material-icons,
.modal-dialog.modal-skill-edit .modal-body .thumbnail-container .action-layer .material-icons,
.modal-dialog.modal-skill-module-edit .modal-body .thumbnail-container .action-layer .material-icons,
.modal-dialog.modal-skill-module-new .modal-body .thumbnail-container .action-layer .material-icons,
.modal-dialog.modal-skill-new .modal-body .thumbnail-container .action-layer .material-icons,
.modal-dialog.modal-skill-submodule-edit .modal-body .thumbnail-container .action-layer .material-icons,
.modal-dialog.modal-skill-submodule-new .modal-body .thumbnail-container .action-layer .material-icons,
.modal-dialog.modal-user-change-password .modal-body .thumbnail-container .action-layer .material-icons,
.modal-dialog.modal-user-edit .modal-body .thumbnail-container .action-layer .material-icons {
  float: left;
  width: 30px;
  height: 30px;
  margin: 0 5px;
  color: #172429;
  font-size: 30px;
  cursor: pointer;
  opacity: 0.7;
}
.modal-dialog.modal-client-edit .modal-body .thumbnail-container .action-layer .material-icons:hover,
.modal-dialog.modal-client-new .modal-body .thumbnail-container .action-layer .material-icons:hover,
.modal-dialog.modal-skill-edit .modal-body .thumbnail-container .action-layer .material-icons:hover,
.modal-dialog.modal-skill-module-edit .modal-body .thumbnail-container .action-layer .material-icons:hover,
.modal-dialog.modal-skill-module-new .modal-body .thumbnail-container .action-layer .material-icons:hover,
.modal-dialog.modal-skill-new .modal-body .thumbnail-container .action-layer .material-icons:hover,
.modal-dialog.modal-skill-submodule-edit .modal-body .thumbnail-container .action-layer .material-icons:hover,
.modal-dialog.modal-skill-submodule-new .modal-body .thumbnail-container .action-layer .material-icons:hover,
.modal-dialog.modal-user-change-password .modal-body .thumbnail-container .action-layer .material-icons:hover,
.modal-dialog.modal-user-edit .modal-body .thumbnail-container .action-layer .material-icons:hover {
  opacity: 1;
}
.modal-dialog.modal-client-edit .modal-footer,
.modal-dialog.modal-client-new .modal-footer,
.modal-dialog.modal-skill-edit .modal-footer,
.modal-dialog.modal-skill-module-edit .modal-footer,
.modal-dialog.modal-skill-module-new .modal-footer,
.modal-dialog.modal-skill-new .modal-footer,
.modal-dialog.modal-skill-submodule-edit .modal-footer,
.modal-dialog.modal-skill-submodule-new .modal-footer,
.modal-dialog.modal-user-change-password .modal-footer,
.modal-dialog.modal-user-edit .modal-footer {
  padding: 5px;
}
.modal-dialog.modal-client-edit .modal-footer .button,
.modal-dialog.modal-client-new .modal-footer .button,
.modal-dialog.modal-skill-edit .modal-footer .button,
.modal-dialog.modal-skill-module-edit .modal-footer .button,
.modal-dialog.modal-skill-module-new .modal-footer .button,
.modal-dialog.modal-skill-new .modal-footer .button,
.modal-dialog.modal-skill-submodule-edit .modal-footer .button,
.modal-dialog.modal-skill-submodule-new .modal-footer .button,
.modal-dialog.modal-user-change-password .modal-footer .button,
.modal-dialog.modal-user-edit .modal-footer .button {
  padding: 5px;
  float: left;
}
.modal-dialog.modal-client-edit .modal-footer .button .material-icons,
.modal-dialog.modal-client-new .modal-footer .button .material-icons,
.modal-dialog.modal-skill-edit .modal-footer .button .material-icons,
.modal-dialog.modal-skill-module-edit .modal-footer .button .material-icons,
.modal-dialog.modal-skill-module-new .modal-footer .button .material-icons,
.modal-dialog.modal-skill-new .modal-footer .button .material-icons,
.modal-dialog.modal-skill-submodule-edit .modal-footer .button .material-icons,
.modal-dialog.modal-skill-submodule-new .modal-footer .button .material-icons,
.modal-dialog.modal-user-change-password .modal-footer .button .material-icons,
.modal-dialog.modal-user-edit .modal-footer .button .material-icons {
  float: right;
  font-size: 30px;
}
.modal-dialog.modal-client-edit .modal-footer .button.button-save,
.modal-dialog.modal-client-new .modal-footer .button.button-save,
.modal-dialog.modal-skill-edit .modal-footer .button.button-save,
.modal-dialog.modal-skill-module-edit .modal-footer .button.button-save,
.modal-dialog.modal-skill-module-new .modal-footer .button.button-save,
.modal-dialog.modal-skill-new .modal-footer .button.button-save,
.modal-dialog.modal-skill-submodule-edit .modal-footer .button.button-save,
.modal-dialog.modal-skill-submodule-new .modal-footer .button.button-save,
.modal-dialog.modal-user-change-password .modal-footer .button.button-save,
.modal-dialog.modal-user-edit .modal-footer .button.button-save {
  float: right;
}
.modal-dialog.modal-item-preview {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.8);
  overflow-y: auto;
}
.modal-dialog.modal-item-preview .modal-content {
  height: 100vh;
  padding: 0;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  border: none;
  border-radius: 0;
}
.modal-dialog.modal-item-preview .modal-content .item-container {
  width: 1524px;
  height: 100vh;
  margin: 0 auto;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-image-container {
  float: left;
  width: 1000px;
  height: 100vh;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-image-container .image {
  position: relative;
  width: 1000px;
  height: calc(100% - 20px);
  margin-top: 20px;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-image-container .image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 6px;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-image-container .image .fa-spinner {
  position: absolute;
  top: 300px;
  left: 500px;
  color: #fff;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-image-container.graphic .image {
  overflow-y: auto;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-image-container.graphic .image img {
  max-height: none;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-video-container {
  float: left;
  width: 1000px;
  height: 100vh;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-video-container .video {
  position: relative;
  width: 1000px;
  margin-top: 30px;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-video-container .video img {
  position: absolute;
  top: 0;
  width: 100%;
  margin: auto;
  cursor: pointer;
  border-radius: 6px;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-video-container .video-container {
  margin-top: 30px;
  border: none;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-data-container {
  float: right;
  width: calc(100vw - 1024px);
  max-width: 500px;
  margin-top: 20px;
  color: #fff;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-name {
  width: calc(100% - 50px);
  margin: 15px 0;
  font-size: 19px;
  font-weight: 400;
  text-transform: uppercase;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-dismiss-button {
  float: right;
  margin-top: 17px;
  cursor: pointer;
  opacity: 0.6;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-dismiss-button:hover {
  opacity: 1;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-dismiss-button img {
  width: 40px;
  height: 40px;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-categories-container {
  display: table;
  width: 100%;
  margin: 0;
  text-transform: uppercase;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-categories-container .category-label {
  float: left;
  font-size: 12px;
  font-weight: 400;
  margin-right: 5px;
  line-height: 20px;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-categories-container .category-item {
  float: left;
  font-size: 9px;
  font-weight: 400;
  margin: 0 5px 5px 0;
  padding: 3px;
  border: 1px solid #fff;
  border-radius: 1px;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-description {
  width: 100%;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  font-weight: 400;
  margin-top: 15px;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-description p {
  font-size: 15px;
  font-weight: 300;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-description a {
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  opacity: 0.9;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-description a:hover {
  opacity: 1;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-thumbnail-list {
  z-index: 2;
  padding: 5px 0;
  display: table;
  width: 100%;
  overflow-y: auto;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-thumbnail-list .thumbnail {
  border: none;
  border-radius: 0;
  margin: 2px;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-thumbnail-list .thumbnail img {
  width: 118px;
  height: auto;
  border-radius: 3px;
}
.modal-dialog.modal-item-preview .modal-content .item-container .item-data-container.graphic .item-thumbnail-list {
  display: none;
}
.modal-dialog.modal-image-editor,
.modal-dialog.modal-images-editor {
  display: table;
  width: unset;
  min-width: 420px;
  margin: auto;
  overflow-y: auto;
}
.modal-dialog.modal-image-editor .modal-content,
.modal-dialog.modal-images-editor .modal-content {
  margin: 1vh 0;
}
.modal-dialog.modal-image-editor .modal-content .modal-header,
.modal-dialog.modal-images-editor .modal-content .modal-header {
  height: 30px;
  padding: 0;
}
.modal-dialog.modal-image-editor .modal-content .modal-header .button,
.modal-dialog.modal-images-editor .modal-content .modal-header .button {
  float: left;
  height: 30px;
  border: none;
  border-radius: 0;
  outline: none;
}
.modal-dialog.modal-image-editor .modal-content .modal-header .button .material-icons,
.modal-dialog.modal-images-editor .modal-content .modal-header .button .material-icons {
  font-size: 28px;
}
.modal-dialog.modal-image-editor .modal-content .modal-header input,
.modal-dialog.modal-images-editor .modal-content .modal-header input {
  float: left;
  height: 26px;
  margin: 2px;
}
.modal-dialog.modal-image-editor .modal-content .modal-body,
.modal-dialog.modal-images-editor .modal-content .modal-body {
  max-height: calc(98vh - 140px);
  text-align: center;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.modal-dialog.modal-image-editor .modal-content .modal-body .image-container .image .image-editor-box,
.modal-dialog.modal-images-editor .modal-content .modal-body .image-container .image .image-editor-box {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #565656;
  cursor: crosshair;
}
.modal-dialog.modal-image-editor .modal-content .modal-body .image-container .image img,
.modal-dialog.modal-images-editor .modal-content .modal-body .image-container .image img {
  border-radius: 0;
}
.modal-dialog.modal-image-editor .modal-content .modal-footer,
.modal-dialog.modal-images-editor .modal-content .modal-footer {
  height: 110px;
  padding: 0 2px;
}
.modal-dialog.modal-image-editor .modal-content .modal-footer .thumbnail-list,
.modal-dialog.modal-images-editor .modal-content .modal-footer .thumbnail-list {
  display: table;
  width: 100%;
}
.modal-dialog.modal-image-editor .modal-content .modal-footer .thumbnail-list .thumbnail img,
.modal-dialog.modal-images-editor .modal-content .modal-footer .thumbnail-list .thumbnail img {
  height: 55px;
  border-radius: 0;
}
.modal-dialog.modal-image-editor .modal-content .modal-footer .button,
.modal-dialog.modal-images-editor .modal-content .modal-footer .button {
  padding: 5px;
  float: left;
}
.modal-dialog.modal-image-editor .modal-content .modal-footer .button .material-icons,
.modal-dialog.modal-images-editor .modal-content .modal-footer .button .material-icons {
  float: right;
  font-size: 30px;
}
.modal-dialog.modal-image-editor .modal-content .modal-footer .button.save-images,
.modal-dialog.modal-images-editor .modal-content .modal-footer .button.save-images {
  float: right;
}
.modal-dialog.modal-image-editor.graphic-editor .modal-content .modal-body .image-container .image img,
.modal-dialog.modal-images-editor.graphic-editor .modal-content .modal-body .image-container .image img {
  height: unset;
}
.modal-dialog.modal-about-gallery {
  width: 90vw;
}
.modal-dialog.modal-about-gallery .modal-content {
  display: table;
  margin: 0 auto;
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.modal-dialog.modal-about-gallery .modal-content .modal-footer,
.modal-dialog.modal-about-gallery .modal-content .modal-header {
  padding: 0;
  border: none;
}
.modal-dialog.modal-about-gallery .modal-content .modal-footer .thumbnail-container,
.modal-dialog.modal-about-gallery .modal-content .modal-header .thumbnail-container {
  display: table;
  margin: 0 auto;
}
.modal-dialog.modal-about-gallery .modal-content .modal-footer .thumbnail-container .thumbnail img,
.modal-dialog.modal-about-gallery .modal-content .modal-header .thumbnail-container .thumbnail img {
  height: 50px;
}
.modal-dialog.modal-about-gallery .modal-content .modal-footer .close-modal,
.modal-dialog.modal-about-gallery .modal-content .modal-header .close-modal {
  color: #fff;
  padding: 10px 0;
  font-size: 50px;
}
.modal-dialog.modal-about-gallery .modal-content .modal-body {
  padding: 0;
}
.modal-dialog.modal-about-gallery .modal-content .modal-body .image-container .image {
  display: table;
  margin: 0 auto;
}
.modal-dialog.modal-about-gallery .modal-content .modal-body .image-container .image img {
  width: 100%;
  border-radius: 4px;
  padding: 2px;
}
.modal-backdrop {
  background: url("/img/background.jpg") center center;
  filter: blur(10px);
}
.conversation-list-container .conversation-item {
  display: table;
  width: 100%;
  margin: 15px 0;
}
.conversation-list-container .conversation-item .panel-heading {
  display: table;
  width: 100%;
  padding: 0;
}
.conversation-list-container .conversation-item .panel-heading .accordion-toggle {
  width: 100%;
  height: 100%;
  display: table;
}
.conversation-list-container .conversation-item .panel-heading .accordion-toggle .topic {
  width: 80%;
  float: left;
  padding: 0 20px;
  line-height: 30px;
  font-size: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}
.conversation-list-container .conversation-item .panel-heading .accordion-toggle .messages-count {
  float: right;
  font-size: 12px;
  line-height: 20px;
  margin: 4px;
  padding: 2px;
  background-color: #0098D0;
  border-radius: 50%;
  text-align: center;
  color: #fff;
}
.conversation-list-container .conversation-item .panel-body {
  padding: 0;
}
.conversation-list-container .conversation-item .panel-body .messages-list {
  max-height: 300px;
  overflow-y: auto;
  padding: 5px;
}
.conversation-list-container .conversation-item .panel-body .messages-list .message {
  display: table;
  width: 100%;
  margin: 0;
  text-align: left;
}
.conversation-list-container .conversation-item .panel-body .messages-list .message .content {
  display: inline-table;
  border-radius: 10px;
  background-color: #94B32F;
  color: #fff;
  font-size: 16px;
  text-align: left;
  margin-top: 10px;
  padding: 10px;
}
.conversation-list-container .conversation-item .panel-body .messages-list .message .date {
  text-align: left;
  font-size: 12px;
}
.conversation-list-container .conversation-item .panel-body .messages-list .message .user-avatar {
  float: left;
}
.conversation-list-container .conversation-item .panel-body .messages-list .message.my-message {
  text-align: right;
}
.conversation-list-container .conversation-item .panel-body .messages-list .message.my-message .content {
  background-color: #0098D0;
  text-align: right;
}
.conversation-list-container .conversation-item .panel-body .messages-list .message.my-message .date {
  text-align: right;
}
.conversation-list-container .conversation-item .panel-body .messages-list .message.my-message .user-avatar {
  float: right;
}
.conversation-list-container .conversation-item .panel-body .messages-list .message.typing {
  padding: 10px;
}
.conversation-list-container .conversation-item .panel-body .input-group {
  padding: 5px;
  border-top: 1px solid #d9d9d9;
}
.conversation-list-container .conversation-item .panel-body .input-group input {
  height: 43px;
  margin: 1px;
  border: 1px solid #E3E3E3;
}
.conversation-list-container .conversation-item .panel-body .input-group input:focus,
.conversation-list-container .conversation-item .panel-body .input-group input:hover {
  border: 1px solid #E3E3E3;
}
.conversation-list-container .conversation-item .panel-body .input-group button {
  width: 120px;
  height: 45px;
  color: #fff;
  background-color: #0098D0;
  font-size: 20px;
}
.menu-hover {
  background-color: #5D7D8F;
  color: #fff;
}
.menu-bar {
  position: fixed;
  top: 0;
  z-index: 1;
  display: table;
  width: 100%;
  height: 50px;
  padding: 0 5px;
  background-color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid #565656;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
}
.menu-bar .logo-container {
  float: left;
  width: 235px;
  cursor: pointer;
}
.menu-bar .logo-container .menu-logo {
  float: left;
  height: 40px;
  margin: 5px;
}
.menu-bar .logo-container .text-container {
  display: table;
  padding: 13px 0;
}
.menu-bar .logo-container .text-container span {
  display: block;
  padding: 0 40px;
  font-size: 10px;
  font-family: 'Gotham';
  font-weight: 400;
  line-height: 6px;
  color: #fff;
}
.menu-bar .logo-container .text-container span.text-big {
  padding: 0;
  font-size: 30px;
  font-weight: lighter;
  line-height: 18px;
}
.menu-bar .menu-section {
  float: left;
  display: table;
  height: 50px;
  margin-left: 20px;
}
.menu-bar .menu-section.right {
  float: right;
}
.menu-bar .menu-section.mobile-menu,
.menu-bar .menu-section.mobile-toggler {
  display: none;
}
.menu-bar .menu-section.search {
  margin-left: -3px;
  opacity: 0.6;
}
.menu-bar .menu-section.search:hover {
  opacity: 1;
}
.menu-bar .menu-section.search .search-panel {
  display: table;
  width: 100%;
}
.menu-bar .menu-section.search .search-panel input {
  width: 80%;
  min-width: 250px;
  height: 34px;
  margin: 8px 0;
  padding: 0 3px;
  background-color: transparent;
  line-height: 34px;
  font-size: 9px;
  color: #fff;
  border: 1px solid #565656;
  border-radius: 6px;
  text-transform: uppercase;
  outline: none;
}
.menu-bar .menu-section.search .search-panel input:focus {
  outline: none;
}
.menu-bar .menu-section.search .search-panel .material-icons {
  position: absolute;
  top: 15px;
  margin-left: -25px;
  font-size: 20px;
  color: #fff;
  z-index: 2;
  cursor: pointer;
}
.menu-bar .menu-section.search .list-container {
  position: relative;
  left: 30px;
  width: 550px;
  margin: 65px 0 10px 20px;
  padding: 0;
  display: table;
  border-radius: 3px;
}
.menu-bar .menu-section.search .list-container .navigation-panel {
  display: table;
  width: 100%;
  margin-bottom: 3px;
}
.menu-bar .menu-section.search .list-container .navigation-panel .paginator {
  display: table;
  float: left;
}
.menu-bar .menu-section.search .list-container .navigation-panel .paginator .page {
  float: left;
  min-width: 34px;
  margin: 0 5px 0 0;
  padding: 3px;
  border-radius: 5px;
  background-color: #fff;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
}
.menu-bar .menu-section.search .list-container .navigation-panel .paginator .page:hover {
  color: #2EB3DE;
}
.menu-bar .menu-section.search .list-container .navigation-panel .paginator .page.active {
  color: #fff;
  background-color: #2EB3DE;
}
.menu-bar .menu-section.search .list-container .navigation-panel .show-all {
  display: table;
  floa8pxt: right;
}
.menu-bar .menu-section.search .list-container .navigation-panel .show-all .material-icons {
  padding: 3px;
  border-radius: 5px;
  background-color: #fff;
  font-size: 34px;
}
.menu-bar .menu-section.search .list-container .list-item {
  margin: 2px 0 0;
  border: none;
  border-radius: 3px;
  padding: 0;
  height: 100px;
  width: 100%;
  background-color: #fff;
}
.menu-bar .menu-section.search .list-container .list-item .image {
  float: left;
  margin: 0;
}
.menu-bar .menu-section.search .list-container .list-item .image img {
  border-radius: 3px 0 0 3px;
}
.menu-bar .menu-section.search .list-container .list-item .info-section {
  position: absolute;
  right: 0;
  width: 400px;
}
.menu-bar .menu-section .item {
  float: left;
  height: 34px;
  margin: 8px 3px;
  padding: 0 8px;
  text-align: center;
  line-height: 34px;
  font-size: 9px;
  color: #fff;
  border: 1px solid #565656;
  border-radius: 6px;
  text-transform: uppercase;
  opacity: 0.6;
  cursor: pointer;
  font-weight: 800;
}
.menu-bar .menu-section .item:hover {
  opacity: 1;
}
.menu-bar .menu-section .item img {
  float: left;
  width: 20px;
  margin: 6px 8px 0 0;
}
.menu-bar .menu-section .item.search-item.active {
  background-color: rgba(255, 255, 255, 0.5);
}
.menu-bar .menu-section .dropdown-container {
  display: table;
  float: left;
}
.menu-bar .menu-section .dropdown-container .title {
  float: left;
  height: 100%;
  padding: 0 20px;
  text-align: center;
  line-height: 50px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}
.menu-bar .menu-section .dropdown-container .title:hover {
  background-color: #5D7D8F;
  color: #fff;
}
.menu-bar .menu-section .dropdown-container .title .material-icons {
  float: left;
  margin: 14px 10px 0 0;
}
.menu-bar .menu-section .dropdown-container .title .material-icons.icon-expand-more {
  float: right;
  margin: 15px 0 0;
}
.menu-bar .menu-section .dropdown-container .title .menu-bar-item {
  float: left;
}
.menu-bar .menu-section .dropdown-container .list {
  display: none;
  position: absolute;
  top: 50px;
  padding: 0;
  background-color: #3B3B3B;
  z-index: 1;
}
.menu-bar .menu-section .dropdown-container .list .item {
  float: initial;
  width: 100%;
  height: 30px;
  cursor: pointer;
  text-align: left;
  line-height: 30px;
  color: #fff;
  font-size: 14px;
  white-space: nowrap;
}
.menu-bar .menu-section .dropdown-container .list .item:hover {
  background-color: #5D7D8F;
  color: #fff;
}
.menu-bar .menu-section .dropdown-container .list .item .counter {
  margin: 0 5px;
  padding: 2px 5px;
  background-color: #fff;
  font-size: 12px;
  border-radius: 3px;
  color: #172429;
}
.menu-bar .menu-section .dropdown-container:hover .list {
  display: table;
}
.menu-bar .admin-container {
  position: fixed;
  bottom: 0;
  right: 20vw;
}
.menu-bar .admin-container .action-box {
  float: left;
  margin: 0 0 0 5px;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.6;
}
.menu-bar .admin-container .action-box .material-icons {
  background-color: transparent;
  color: white;
  font-size: 50px;
}
.menu-bar .admin-container .action-box:hover {
  opacity: 1;
}
@media (max-width: 1280px) {
  .menu-bar .admin-container {
    display: none;
  }
  .menu-bar .menu-section .item {
    padding: 0 1px;
  }
  .menu-bar .menu-section .item.language-item {
    padding: 0 8px;
  }
  .menu-bar .menu-section .item img {
    margin: 7px;
  }
  .menu-bar .menu-section .item span {
    display: none;
  }
}
@media (max-width: 960px) {
  .menu-bar .menu-section .item.search-item {
    display: block;
  }
}
@media screen and (max-width: 783px) {
  .menu-bar {
    background-color: rgba(0, 0, 0, 0.92);
    z-index: 9999;
  }
  .menu-bar .menu-section {
    display: none;
  }
  .menu-bar .menu-section.mobile-toggler {
    display: block;
  }
  .menu-bar .menu-section.mobile-toggler .item {
    width: 44px;
    height: 44px;
    margin: 3px;
  }
  .menu-bar .menu-section.mobile-toggler .item img {
    width: 27px;
    margin: 8px 6px;
  }
  .menu-bar .menu-section.mobile-menu {
    display: table;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 7px 0;
  }
  .menu-bar .menu-section.mobile-menu .search-panel {
    float: right;
    width: 80%;
    height: 60px;
  }
  .menu-bar .menu-section.mobile-menu .search-panel input {
    width: 100%;
    height: 60px;
    margin: 1px;
    padding: 0 3px;
    background-color: transparent;
    line-height: 34px;
    font-size: 24px;
    color: #fff;
    border: 1px solid #565656;
    border-radius: 6px;
    text-transform: uppercase;
    outline: none;
  }
  .menu-bar .menu-section.mobile-menu .search-panel input:focus {
    outline: none;
  }
  .menu-bar .menu-section.mobile-menu .search-panel .material-icons {
    position: absolute;
    top: 60px;
    right: 20px;
    margin: 0;
    font-size: 40px;
    line-height: 20vw;
    color: #fff;
    z-index: 2;
    cursor: pointer;
  }
  .menu-bar .menu-section.mobile-menu .mobile-menu-column {
    float: right;
    width: 62px;
    margin: 0;
    display: table;
  }
  .menu-bar .menu-section.mobile-menu .mobile-menu-column .item {
    width: 58px;
    height: 58px;
    margin: 2px 0;
    float: initial;
    display: table;
    font-size: 18px;
    line-height: 56px;
    background-color: rgba(255, 255, 255, 0.05);
  }
  .menu-bar .menu-section.mobile-menu .mobile-menu-column .item.active {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .menu-bar .menu-section.mobile-menu .mobile-menu-column .item .icon {
    width: 56px;
    height: 40px;
  }
  .menu-bar .menu-section.mobile-menu .mobile-menu-column .item .icon img {
    width: 40px;
    height: 34px;
    margin: 6px 8px 3px;
  }
  .menu-bar .menu-section.mobile-menu .mobile-menu-column .item .title {
    width: 56px;
    line-height: 8px;
    font-size: 7px;
    font-weight: 600;
  }
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  margin: 0;
  padding: 8px 10px;
  background-color: transparent;
  color: #fff;
}
.footer .copyright {
  float: right;
  font-size: 9px;
  font-weight: 300;
}
.footer .copyright .blue {
  color: #2EB3DE;
}
.footer .links {
  float: right;
}
.footer .links .item {
  margin: 0 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.footer .links .item:hover {
  color: #2EB3DE;
}
.bounce-spinner {
  position: fixed;
  margin: 0;
  width: 100vw;
  height: 100vh;
  padding: 46vh 51vw;
  text-align: center;
  z-index: 12;
}
.bounce-spinner .dark-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #172429;
  z-index: 11;
}
.bounce-spinner.light .dark-layer {
  opacity: 0.7;
}
.bounce-spinner .bounce {
  position: fixed;
  margin-left: -105px;
  margin-top: -50px;
  z-index: 12;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bounce-spinner .bounce img {
  width: 180px;
}
.bounce-spinner.bounce-spinner-message {
  position: relative;
  float: right;
  width: 30px;
  height: 20px;
  margin: 10px 0 0;
  padding: 0;
}
.bounce-spinner.bounce-spinner-message .bounce {
  position: relative;
  width: 8px;
  height: 8px;
  margin: 0 1px;
  background-color: #d9d9d9;
}
.bounce-spinner.bounce-spinner-message .bounce.first {
  margin-left: -10px;
}
.bounce-spinner.bounce-spinner-message .bounce.second {
  margin-left: 0;
}
.bounce-spinner.bounce-spinner-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 133px 300px;
}
.bounce-spinner.bounce-spinner-modal .dark-layer {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.bounce-spinner.bounce-spinner-modal .bounce {
  width: 18px;
  height: 18px;
  margin-left: 20px;
}
.bounce-spinner.bounce-spinner-modal .bounce.first {
  margin-left: -40px;
}
.bounce-spinner.bounce-spinner-modal .bounce.second {
  margin-left: -10px;
}
@media screen and (max-width: 580px) {
  .bounce-spinner .bounce {
    margin-left: -95px;
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  animation: rotating 2s linear infinite;
}
.icheckbox {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #565656;
  border-radius: 6px;
  cursor: pointer;
}
.icheckbox.hover {
  border-color: #E02B2B;
}
.icheckbox.disabled {
  cursor: default;
}
.icheckbox.checked {
  background: url("/img/svg/checkbox.svg");
  background-size: 16px 16px;
}
.icheckbox.checked.disabled {
  background-size: 8px 8px;
}
.iradio {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 18px;
  height: 18px;
  background: url("/img/icons/icheck.png") no-repeat;
  border: none;
  cursor: pointer;
  background-position: -100px 0;
}
.iradio.hover {
  background-position: -120px 0;
}
.iradio.disabled {
  background-position: -160px 0;
  cursor: default;
}
.iradio.checked {
  background-position: -140px 0;
}
.iradio.checked.disabled {
  background-position: -180px 0;
}
.content-section {
  position: absolute;
  width: calc(100vw - 105px);
  height: calc(100vh - 105px);
  padding: 10px 50px;
  margin: 50px 0 0 105px;
  overflow-y: auto;
}
.content-section .home-page .latest-items-container {
  display: table;
  width: 100%;
}
.content-section .home-page .latest-items-container .header {
  width: 100%;
  border-bottom: 2px solid #E3E3E3;
  font-size: 20px;
  line-height: 40px;
  background-color: #fff;
  border-radius: 3px 3px 0 0;
}
.content-section .home-page .latest-items-container .header span {
  margin: 10px;
}
.content-section .settings-page {
  width: 80vw;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.content-section .settings-page .settings-page-tabs .nav-tabs {
  background-color: transparent;
}
.content-section .settings-page .settings-page-tabs .nav-tabs li {
  margin: 0;
}
.content-section .settings-page .settings-page-tabs .nav-tabs li a {
  background-color: #fff;
}
.content-section .settings-page .settings-page-tabs .tab-content {
  background-color: #fff;
}
.content-section .settings-page .settings-page-tabs .tab-content .tab-pane {
  margin: 0 auto;
  padding: 20px;
}
.content-section .settings-page .settings-page-tabs .tab-content .tab-pane .header {
  width: 70vw;
  margin: 0 auto;
  text-align: center;
  font-size: 30px;
  border-bottom: 1px solid #d9d9d9;
}
.content-section .settings-page .settings-page-tabs .tab-content .tab-pane .form-item {
  width: 100%;
}
.content-section .settings-page .settings-page-tabs .tab-content .tab-pane .form-item .button {
  display: table;
  float: unset;
  height: 40px;
  width: 140px;
  margin: 5px auto;
  padding: 8px 10px;
  font-size: 16px;
  white-space: nowrap;
  color: #172429;
}
.content-section .settings-page .settings-page-tabs .tab-content .tab-pane .form-item .message-empty {
  display: table-row;
  text-align: center;
  color: #ccc;
  font-size: 16px;
  line-height: 50px;
}
.content-section .settings-page .settings-page-tabs .tab-content .tab-pane .form-item .about-thumbnail-list {
  display: table;
  width: 100%;
  margin: 5px auto;
}
.content-section .settings-page .settings-page-tabs .tab-content .tab-pane .form-item .about-thumbnail-list .about-thumbnail-image {
  display: block;
  margin: 10px -5px 10px 15px;
  float: left;
}
.content-section .settings-page .settings-page-tabs .tab-content .tab-pane .form-item .about-thumbnail-list .about-thumbnail-image img {
  width: 120px;
  border-radius: 10px;
  border: 2px solid #fff;
}
.content-section .settings-page .settings-page-tabs .tab-content .tab-pane .form-item .about-thumbnail-list .about-thumbnail-image img.new {
  border: 2px solid #0098D0;
}
.content-section .settings-page .settings-page-tabs .tab-content .tab-pane .form-item .about-thumbnail-list .about-thumbnail-image img.main {
  border: 2px solid #94B32F;
}
.content-section .settings-page .settings-page-tabs .tab-content .tab-pane .form-item .about-thumbnail-list .about-thumbnail-image .action-layer {
  position: absolute;
  margin: 5px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 20px;
}
.content-section .settings-page .settings-page-tabs .tab-content .tab-pane .form-item .about-thumbnail-list .about-thumbnail-image .action-layer:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
.content-section .settings-page .settings-page-tabs .tab-content .tab-pane .form-item .about-thumbnail-list .about-thumbnail-image .action-layer .material-icons {
  float: left;
  width: 30px;
  height: 30px;
  margin: 0 5px;
  color: #172429;
  font-size: 30px;
  cursor: pointer;
  opacity: 0.7;
}
.content-section .settings-page .settings-page-tabs .tab-content .tab-pane .form-item .about-thumbnail-list .about-thumbnail-image .action-layer .material-icons:hover {
  opacity: 1;
}
.content-section .settings-page .button {
  float: right;
  margin-top: 10px;
  padding: 8px 10px;
  font-size: 18px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.05);
}
.content-section .settings-page .button span {
  margin-right: 5px;
}
.content-section .settings-page .button.delete-account {
  float: left;
  border-color: #E02B2B;
}
.content-section .settings-page .button.delete-account:hover {
  background-color: #E02B2B;
  color: #fff;
}
.content-section .form-container {
  display: table;
  width: 100%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 3px;
}
.content-section .form-container .section {
  display: table;
  width: 100%;
  margin: 20px auto;
  text-align: center;
}
.content-section .form-container .section .header {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  border-bottom: 1px solid #d9d9d9;
}
.content-section .form-container .section.basics .form-item {
  width: 100%;
  padding: 2px 5px;
}
.content-section .form-container .section.basics .form-item input {
  float: right;
  width: 70%;
  padding: 5px;
  font-size: 17px;
  text-align: center;
}
.content-section .form-container .section.basics .form-item .item-description-editor {
  float: right;
  width: 70%;
}
.content-section .form-container .section.basics .form-item .item-description-editor .btn-toolbar {
  margin: 5px 0 5px -5px;
}
.content-section .form-container .section.basics .form-item .subcategories {
  float: right;
  width: 70%;
  margin-bottom: 12px;
}
.content-section .form-container .section.basics .form-item.checkbox input {
  position: relative;
  float: right;
  width: 26px;
  height: 26px;
}
.content-section .form-container .section.gallery .button,
.content-section .form-container .section.graphic .button {
  display: table;
  float: unset;
  height: 40px;
  width: 140px;
  margin: 5px auto;
  padding: 8px 10px;
  font-size: 16px;
  white-space: nowrap;
}
.content-section .form-container .section.gallery .message-empty,
.content-section .form-container .section.graphic .message-empty {
  color: #ccc;
  font-size: 16px;
  line-height: 50px;
}
.content-section .form-container .section.gallery .thumbnail-list,
.content-section .form-container .section.graphic .thumbnail-list {
  display: table;
  width: 100%;
  margin: 5px auto;
}
.content-section .form-container .section.gallery .thumbnail-list .thumbnail-image,
.content-section .form-container .section.graphic .thumbnail-list .thumbnail-image {
  display: block;
  margin: 10px -5px 10px 15px;
  float: left;
}
.content-section .form-container .section.gallery .thumbnail-list .thumbnail-image img,
.content-section .form-container .section.graphic .thumbnail-list .thumbnail-image img {
  border-radius: 10px;
  border: 2px solid #fff;
  cursor: pointer;
}
.content-section .form-container .section.gallery .thumbnail-list .thumbnail-image img.new,
.content-section .form-container .section.graphic .thumbnail-list .thumbnail-image img.new {
  border: 2px solid #0098D0;
}
.content-section .form-container .section.gallery .thumbnail-list .thumbnail-image img.main,
.content-section .form-container .section.graphic .thumbnail-list .thumbnail-image img.main {
  border: 2px solid #94B32F;
}
.content-section .form-container .section.gallery .thumbnail-list .thumbnail-image .action-layer,
.content-section .form-container .section.graphic .thumbnail-list .thumbnail-image .action-layer {
  position: absolute;
  margin: 5px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 20px;
}
.content-section .form-container .section.gallery .thumbnail-list .thumbnail-image .action-layer:hover,
.content-section .form-container .section.graphic .thumbnail-list .thumbnail-image .action-layer:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
.content-section .form-container .section.gallery .thumbnail-list .thumbnail-image .action-layer .material-icons,
.content-section .form-container .section.graphic .thumbnail-list .thumbnail-image .action-layer .material-icons {
  float: left;
  width: 30px;
  height: 30px;
  margin: 0 5px;
  color: #172429;
  font-size: 30px;
  cursor: pointer;
  opacity: 0.7;
}
.content-section .form-container .section.gallery .thumbnail-list .thumbnail-image .action-layer .material-icons:hover,
.content-section .form-container .section.graphic .thumbnail-list .thumbnail-image .action-layer .material-icons:hover {
  opacity: 1;
}
.content-section .form-container .section.youtube-video .youtube-video-url {
  float: right;
  display: table;
  width: 380px;
  margin: 10px 0;
}
.content-section .form-container .section.youtube-video .youtube-video-url input {
  float: left;
  width: 350px;
  height: 50px;
}
.content-section .form-container .section.youtube-video .youtube-video-url .youtube-video-add {
  float: right;
  opacity: 0.6;
}
.content-section .form-container .section.youtube-video .youtube-video-url .youtube-video-add .fa {
  font-size: 30px;
  line-height: 50px;
}
.content-section .form-container .section.youtube-video .youtube-video-url .youtube-video-add:hover {
  opacity: 1;
}
.content-section .form-container .section.youtube-video .youtube-videos {
  display: table;
  width: 100%;
  margin: 20px 0;
}
.content-section .form-container .section.youtube-video .youtube-videos .youtube-video-item {
  float: left;
  margin: 5px;
  opacity: 0.7;
  cursor: pointer;
}
.content-section .form-container .section.youtube-video .youtube-videos .youtube-video-item img {
  width: 250px;
  height: auto;
  border-radius: 3px;
}
.content-section .form-container .section.youtube-video .youtube-videos .youtube-video-item .action-layer {
  position: absolute;
  margin: 5px;
  padding: 10px 5px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
}
.content-section .form-container .section.youtube-video .youtube-videos .youtube-video-item .action-layer:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
.content-section .form-container .section.youtube-video .youtube-videos .youtube-video-item .action-layer .material-icons {
  float: left;
  width: 30px;
  height: 30px;
  margin: 0 5px;
  color: #172429;
  font-size: 30px;
  cursor: pointer;
  opacity: 0.7;
}
.content-section .form-container .section.youtube-video .youtube-videos .youtube-video-item .action-layer .material-icons:hover {
  opacity: 1;
}
.content-section .form-container .section.youtube-video .youtube-videos .youtube-video-item.main,
.content-section .form-container .section.youtube-video .youtube-videos .youtube-video-item:hover {
  opacity: 1;
}
.content-section .form-container .section.category {
  text-align: left;
}
.content-section .form-container .section.category .category-container {
  width: 97%;
  margin: 0 auto;
}
.content-section .form-container .section.category .category-container .selected-list {
  display: table;
  width: 100%;
  margin: 20px auto;
}
.content-section .form-container .section.category .category-container .categories-tree {
  float: left;
  display: table;
  margin: 5px 0 0;
}
.content-section .form-container .section.category .category-container .categories-tree.with-children {
  display: table;
  width: 100%;
  padding: 10px;
  border: 1px solid #565656;
  border-radius: 3px;
}
.content-section .form-container .section.category .category-container .categories-tree .subcategories-tree {
  display: table;
  width: 100%;
  float: inherit;
}
.content-section .form-container .save-btn {
  float: right;
  padding: 8px 10px;
  font-size: 18px;
}
.content-section .form-container .save-btn span {
  margin-right: 5px;
}
.content-section .form-container .cancel-btn {
  float: left;
  height: 57px;
  padding: 0 20px;
  font-size: 25px;
}
.content-section .form-container .cancel-btn:hover {
  border-color: #172429;
  color: #172429;
  background-color: #fff;
}
.content-section .list-page {
  width: 100%;
}
.content-section .list-page .list-header {
  margin: 10px 0;
  padding: 2px 0;
  background-color: transparent;
  color: #fff;
  border: 1px solid #565656;
  border-radius: 6px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
}
.content-section .list-page .subcategories-toggler {
  margin: 10px 0;
  padding: 2px 0;
  background-color: transparent;
  color: #fff;
  border: 1px solid #565656;
  border-radius: 6px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  font-weight: 400;
}
.content-section .list-page .subcategories-toggler img {
  float: right;
  width: 20px;
  margin: 0 5px 0 -25px;
}
.content-section .list-page .subcategories-toggler.rotated img {
  transform: rotate(180deg);
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=-1, M12=0, M21=0, M22=-1, DX=0, DY=0, SizingMethod='auto expand');
}
.content-section .list-page .subcategories-container {
  display: table;
  width: 100%;
  margin: 20px 0;
}
.content-section .list-page .subcategories-container .subcategory-item {
  width: calc(25% - 5px);
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 800;
}
.content-section .list-page .subcategories-container .subcategory-item:hover {
  opacity: 1;
}
.content-section .list-page .subcategories-container .subcategory-item .icheckbox {
  top: -1px;
  margin-right: 5px;
}
.content-section .list-page .subcategories-container .subcategory-item .category-counter {
  margin: 0 3px;
}
.content-section .list-page .list-container {
  display: table;
  width: 100%;
}
.content-section .list-page .list-container .list-item {
  float: left;
  width: 19.5%;
  margin: 5px 0.25%;
  opacity: 0.9;
  cursor: pointer;
  text-align: center;
}
.content-section .list-page .list-container .list-item:hover {
  opacity: 1;
}
.content-section .list-page .list-container .list-item .info-section {
  height: 40px;
  padding: 2px;
  text-transform: uppercase;
  text-align: center;
  border-style: solid;
  border-color: #565656;
  border-width: 0 1px 1px;
  background: rgba(38, 38, 38, 0.68);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(38, 38, 38, 0.68)), color-stop(22%, rgba(14, 14, 14, 0.75)), color-stop(35%, rgba(0, 0, 0, 0.75)), color-stop(65%, rgba(0, 0, 0, 0.75)), color-stop(78%, rgba(7, 7, 7, 0.75)), color-stop(100%, rgba(19, 19, 19, 0.68)));
  background: linear-gradient(to right, rgba(38, 38, 38, 0.68) 0%, rgba(14, 14, 14, 0.75) 22%, rgba(0, 0, 0, 0.75) 35%, rgba(0, 0, 0, 0.75) 65%, rgba(7, 7, 7, 0.75) 78%, rgba(19, 19, 19, 0.68) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#262626', endColorstr='#131313', GradientType=1);
}
.content-section .list-page .list-container .list-item .info-section .title {
  font-size: 13px;
  color: #fff;
  font-weight: 400;
}
.content-section .list-page .list-container .list-item .info-section .red-tags {
  font-size: 11px;
  color: #E02B2B;
  font-weight: 600;
}
.content-section .list-page .list-container .list-item .owner-actions-section {
  position: absolute;
  z-index: 1;
}
.content-section .list-page .list-container .list-item .owner-actions-section .action-button {
  width: 100%;
  height: 30px;
  float: right;
  opacity: 0.7;
  border: 1px solid #d9d9d9;
  font-size: 12px;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}
.content-section .list-page .list-container .list-item .owner-actions-section .action-button:hover {
  color: #fff;
  opacity: 1;
}
.content-section .list-page .list-container .list-item .owner-actions-section .action-button.remove {
  background-color: #E02B2B;
}
.content-section .list-page .list-container .list-item .owner-actions-section .action-button.edit {
  background-color: #FF9900;
}
.content-section .list-page .list-container .list-item .owner-actions-section .action-button.item-activate {
  background-color: #94B32F;
}
.content-section .list-page .list-container .list-item .description {
  width: 322px;
  max-height: 200px;
  padding: 5px;
  color: #fff;
  font-size: 12px;
  text-align: left;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}
.content-section .list-page .list-container .list-item.gallery .thumbnail-container,
.content-section .list-page .list-container .list-item.graphic .thumbnail-container {
  width: 100%;
}
.content-section .list-page .list-container .list-item.gallery .thumbnail-container img,
.content-section .list-page .list-container .list-item.graphic .thumbnail-container img {
  width: 100%;
  border-style: solid;
  border-color: #565656;
  border-width: 1px 1px 0 1px;
}
.content-section .list-page .list-container .list-item.video .video-thumbnail-container {
  border-style: solid;
  border-color: #565656;
  border-width: 1px 1px 0 1px;
}
.content-section .list-page .list-container .list-item.video .video-thumbnail-container img {
  width: 100%;
  height: 100%;
}
.content-section .list-page .list-container .list-item.admin-view {
  height: unset;
}
.content-section .list-page .list-container .list-item.admin-view.video {
  margin-bottom: 50px;
}
.content-section .list-page.showreel .list-container {
  width: 1024px;
  margin: 10px auto;
}
.content-section .list-page.showreel .list-container .list-item {
  width: 1024px;
  min-height: 700px;
  margin: 0;
  opacity: 1;
}
.content-section .list-page.showreel .list-container .list-item .description {
  width: 100%;
}
.content-section .list-page.showreel .list-container .list-item.video .video-thumbnail-container {
  border: 1px solid #565656;
}
.content-section .list-page.showreel .list-container .list-item.video .video-thumbnail-container img {
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.content-section .category-list-page {
  width: 100%;
}
.content-section .category-list-page .category-list-container {
  display: table;
  width: 100%;
  margin-top: 30px;
}
.content-section .category-list-page .category-list-container .category-list-item {
  display: table;
  float: left;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
}
.content-section .category-list-page .category-list-container .category-list-item .action-button {
  width: 60px;
  height: 35px;
  float: left;
  margin: 7px 1px;
  opacity: 0.7;
  border: 1px solid #d9d9d9;
  font-size: 13px;
  line-height: 35px;
  text-align: center;
  cursor: pointer;
}
.content-section .category-list-page .category-list-container .category-list-item .action-button:hover {
  color: #fff;
  opacity: 1;
}
.content-section .category-list-page .category-list-container .category-list-item .action-button.remove {
  background-color: #E02B2B;
}
.content-section .category-list-page .category-list-container .category-list-item .action-button.edit {
  background-color: #FF9900;
}
.content-section .category-list-page .category-list-container .category-list-item .action-button.activate {
  background-color: #94B32F;
}
.content-section .category-list-page .category-list-container .category-list-item .action-button.block {
  background-color: #FF6D4D;
}
.content-section .category-list-page .category-list-container .category-list-item .field {
  float: left;
  height: 100%;
  margin: 0 20px;
  padding: 10px 0;
  text-align: center;
}
.content-section .category-list-page .category-list-container .category-list-item .field.title {
  min-width: 100px;
  font-size: 16px;
  line-height: 32px;
}
.content-section .category-list-page .category-list-container .category-list-item .field.depth,
.content-section .category-list-page .category-list-container .category-list-item .field.items-count {
  float: right;
  min-width: 50px;
  font-size: 18px;
  line-height: 30px;
}
.content-section .category-list-page .category-list-container .category-list-item .field.description {
  float: right;
  width: 500px;
  text-align: left;
  font-size: 12px;
  line-height: 18px;
}
.content-section .user-list-page {
  width: 100%;
}
.content-section .user-list-page .user-list-container {
  display: table;
  width: 100%;
}
.content-section .user-list-page .user-list-container .user-list-item {
  display: table;
  float: left;
  width: 100%;
  height: 50px;
  background-color: #fff;
  border-radius: 3px;
  margin-bottom: 5px;
}
.content-section .user-list-page .user-list-container .user-list-item .action-button {
  height: 50px;
  float: left;
  opacity: 0.7;
  text-align: center;
  cursor: pointer;
}
.content-section .user-list-page .user-list-container .user-list-item .action-button:hover {
  opacity: 1;
}
.content-section .user-list-page .user-list-container .user-list-item .action-button .material-icons {
  margin-top: 10px;
  font-size: 30px;
}
.content-section .user-list-page .user-list-container .user-list-item .field {
  float: left;
  height: 100%;
  margin: 0 20px;
  text-align: center;
  line-height: 50px;
}
.content-section .item-page .seller-container {
  position: fixed;
  width: 15vw;
  min-width: 220px;
  right: 1vw;
  top: 220px;
  background-color: #fff;
  box-shadow: 0 0 12px 2px #99a0a6;
  border-radius: 3px;
}
.content-section .item-page .seller-container .data-item {
  display: table;
  margin: 5px auto;
}
.content-section .item-page .seller-container .data-item.conversation {
  font-size: 20px;
  text-align: center;
}
.content-section .item-page .seller-container .data-item.conversation .material-icons {
  position: relative;
  top: 8px;
  font-size: 30px;
  color: #94B32F;
}
.content-section .item-page .seller-container .data-item.name {
  font-size: 20px;
  text-align: center;
  border-bottom: 1px solid #E3E3E3;
}
.content-section .item-page .seller-container .data-item.name .material-icons {
  font-size: 70px;
  margin: 10px 0;
  display: block;
  padding: 5px;
  border-radius: 50%;
  cursor: default;
  box-shadow: 0 0 15px 0 #8c858c;
}
.content-section .item-page .seller-container .data-item.address,
.content-section .item-page .seller-container .data-item.email,
.content-section .item-page .seller-container .data-item.phone {
  font-size: 20px;
  text-align: center;
}
.content-section .item-page .seller-container .data-item.address .material-icons,
.content-section .item-page .seller-container .data-item.email .material-icons,
.content-section .item-page .seller-container .data-item.phone .material-icons {
  position: relative;
  top: 8px;
  font-size: 30px;
  color: #0098D0;
}
.content-section .item-page .seller-container .data-item.email .material-icons {
  color: #FF9900;
}
.content-section .item-page .seller-container .data-item.address {
  font-size: 16px;
}
.content-section .item-page .seller-container .data-item.address .material-icons {
  color: #6BBDBF;
}
.content-section .item-page .seller-container .portals-panel {
  padding: 10px;
  border-top: 1px solid #E3E3E3;
  text-align: center;
}
.content-section .item-page .seller-container .portals-panel .portal-link {
  margin: 5px;
}
.content-section .item-page .item-section {
  width: 100%;
  margin: 0 0 10px;
}
.content-section .item-page .item-section .header {
  display: table;
  width: 100%;
  text-align: left;
  border-radius: 3px 3px 0 0;
  border-bottom: 1px solid #d9d9d9;
  margin: 0 auto;
  background-color: #fff;
}
.content-section .item-page .item-section .header span {
  margin: 0 10px;
  font-size: 20px;
  line-height: 40px;
}
.content-section .item-page .item-section .header .price {
  float: right;
  font-size: 28px;
  color: #2EB3DE;
}
.content-section .item-page .item-section .header .portal-link img {
  float: right;
  width: 30px;
  margin: 0 5px;
  opacity: 0.8;
}
.content-section .item-page .item-section .header .portal-link img:hover {
  opacity: 1;
}
.content-section .item-page .item-section .description {
  margin: 0 auto;
  padding: 5px;
  font-size: 17px;
  background-color: #fff;
  border-radius: 0 0 3px 3px;
}
.content-section .item-page .item-section .description img {
  max-width: 250px !important;
}
.content-section .item-page .item-section.gallery .gallery-container {
  background-color: #fff;
  display: table;
  width: 100%;
  border-radius: 0 0 3px 3px;
}
.content-section .item-page .item-section.other-items {
  background-color: none;
}
.modal-terms .terms-container {
  padding: 0 20px 10px;
}
.modal-terms .terms-container .term-item {
  font-size: 14px;
  margin: 3px 0;
}
.modal-terms .page-header {
  margin: 20px 20px 10px;
  font-size: 20px;
}
.conversations-page .conversation-list-container .conversation-item {
  display: table;
  width: 100%;
  margin: 15px 0;
}
.conversations-page .conversation-list-container .conversation-item .panel-heading {
  display: table;
  width: 100%;
  padding: 0;
}
.conversations-page .conversation-list-container .conversation-item .panel-heading .accordion-toggle {
  width: 100%;
  height: 100%;
  display: table;
}
.conversations-page .conversation-list-container .conversation-item .panel-heading .accordion-toggle .topic {
  width: 80%;
  float: left;
  padding: 0 20px;
  line-height: 30px;
  font-size: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}
.conversations-page .conversation-list-container .conversation-item .panel-heading .accordion-toggle .messages-count {
  float: right;
  font-size: 12px;
  line-height: 20px;
  margin: 4px;
  padding: 2px;
  background-color: #0098D0;
  border-radius: 50%;
  text-align: center;
  color: #fff;
}
.conversations-page .conversation-list-container .conversation-item .panel-body {
  padding: 0;
}
.conversations-page .conversation-list-container .conversation-item .panel-body .messages-list {
  max-height: 300px;
  overflow-y: auto;
  padding: 5px;
}
.conversations-page .conversation-list-container .conversation-item .panel-body .messages-list .message {
  display: table;
  width: 100%;
  margin: 0;
  text-align: left;
}
.conversations-page .conversation-list-container .conversation-item .panel-body .messages-list .message .content {
  display: inline-table;
  border-radius: 10px;
  background-color: #94B32F;
  color: #fff;
  font-size: 16px;
  text-align: left;
  margin-top: 10px;
  padding: 10px;
}
.conversations-page .conversation-list-container .conversation-item .panel-body .messages-list .message .date {
  text-align: left;
  font-size: 12px;
}
.conversations-page .conversation-list-container .conversation-item .panel-body .messages-list .message .user-avatar {
  float: left;
}
.conversations-page .conversation-list-container .conversation-item .panel-body .messages-list .message.my-message {
  text-align: right;
}
.conversations-page .conversation-list-container .conversation-item .panel-body .messages-list .message.my-message .content {
  background-color: #0098D0;
  text-align: right;
}
.conversations-page .conversation-list-container .conversation-item .panel-body .messages-list .message.my-message .date {
  text-align: right;
}
.conversations-page .conversation-list-container .conversation-item .panel-body .messages-list .message.my-message .user-avatar {
  float: right;
}
.conversations-page .conversation-list-container .conversation-item .panel-body .messages-list .message.typing {
  padding: 10px;
}
.conversations-page .conversation-list-container .conversation-item .panel-body .input-group {
  padding: 5px;
  border-top: 1px solid #d9d9d9;
}
.conversations-page .conversation-list-container .conversation-item .panel-body .input-group input {
  height: 43px;
  margin: 1px;
  border: 1px solid #E3E3E3;
}
.conversations-page .conversation-list-container .conversation-item .panel-body .input-group input:focus,
.conversations-page .conversation-list-container .conversation-item .panel-body .input-group input:hover {
  border: 1px solid #E3E3E3;
}
.conversations-page .conversation-list-container .conversation-item .panel-body .input-group button {
  width: 120px;
  height: 45px;
  color: #fff;
  background-color: #0098D0;
  font-size: 20px;
}
.clients-page {
  width: 100%;
}
.clients-page .header {
  margin: 20px 0 10px 0;
  padding: 2px 0;
  background-color: rgba(255, 255, 255, 0.05);
  color: #fff;
  border: 1px solid #565656;
  border-radius: 6px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
}
.clients-page .clients-list-container {
  display: table;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 30px;
  background-color: rgba(255, 255, 255, 0.05);
  color: #fff;
  border: 1px solid #565656;
  border-radius: 6px;
}
.clients-page .clients-list-container .title {
  width: 100%;
  text-align: left;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
}
.clients-page .clients-list-container .client-list-item {
  float: left;
  display: table;
  margin: 10px 25px;
}
.clients-page .clients-list-container .client-list-item img {
  width: 200px;
}
.clients-page .clients-list-container .client-list-item .name {
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
}
.skills-page {
  width: 100%;
}
.skills-page .module-container {
  display: table;
  width: 100%;
}
.skills-page .module-container .module-header {
  margin: 20px 5px 10px;
  padding: 2px 0;
  background-color: rgba(255, 255, 255, 0.05);
  color: #fff;
  border: 1px solid #565656;
  border-radius: 6px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
}
.skills-page .module-container .submodule-container {
  float: left;
  display: table;
  width: 24%;
  min-width: 250px;
  margin: 1% 0.5%;
  padding: 10px 10px 15px;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid #565656;
  border-radius: 6px;
}
.skills-page .module-container .submodule-container .submodule-header {
  margin-bottom: 10px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
}
.skills-page .module-container .submodule-container .skill-container {
  width: 100%;
  display: inline-table;
}
.skills-page .module-container .submodule-container .skill-container img {
  float: left;
  width: 70px;
}
.skills-page .module-container .submodule-container .skill-container .text-section {
  float: left;
  display: table;
  margin: 16px 0 0 12px;
}
.skills-page .module-container .submodule-container .skill-container .text-section .skill-name {
  margin-bottom: 4px;
  color: #fff;
  text-transform: uppercase;
  font-size: 11px;
}
.skills-page .module-container .submodule-container .skill-container .text-section .skill-description {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
}
.admin-clients-page {
  width: 100%;
}
.admin-clients-page .clients-list-container .client-list-item {
  height: 50px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
}
.admin-clients-page .clients-list-container .client-list-item .action-button {
  width: 60px;
  height: 35px;
  float: left;
  margin: 7px 1px;
  opacity: 0.7;
  border: 1px solid #d9d9d9;
  font-size: 13px;
  line-height: 35px;
  text-align: center;
  cursor: pointer;
}
.admin-clients-page .clients-list-container .client-list-item .action-button:hover {
  color: #fff;
  opacity: 1;
}
.admin-clients-page .clients-list-container .client-list-item .action-button.remove {
  background-color: #E02B2B;
}
.admin-clients-page .clients-list-container .client-list-item .action-button.edit {
  background-color: #FF9900;
}
.admin-clients-page .clients-list-container .client-list-item .field {
  float: left;
  height: 100%;
  margin: 0 20px;
  text-align: center;
  font-size: 18px;
  line-height: 50px;
  text-transform: uppercase;
}
.admin-clients-page .clients-list-container .client-list-item .field.name {
  font-size: 16px;
}
.admin-skills-page {
  width: 100%;
}
.admin-skills-page .drag-n-drop .left-section {
  float: left;
  width: 110px;
}
.admin-skills-page .drag-n-drop .left-section .module-add-container {
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.2);
}
.admin-skills-page .drag-n-drop .left-section .module-add-container .header {
  background-color: #fff;
  text-align: center;
}
.admin-skills-page .drag-n-drop .left-section .module-add-container .header span {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 30px;
}
.admin-skills-page .drag-n-drop .left-section .module-add-container .header .material-icons {
  float: right;
  font-size: 30px;
}
.admin-skills-page .drag-n-drop .left-section .module-add-container .action-layer {
  width: 80px;
  margin: 9px auto 0;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
}
.admin-skills-page .drag-n-drop .left-section .module-add-container .action-layer:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
.admin-skills-page .drag-n-drop .left-section .module-add-container .action-layer .material-icons {
  padding: 20px 26px;
  color: #172429;
  font-size: 30px;
  cursor: pointer;
  opacity: 0.7;
}
.admin-skills-page .drag-n-drop .left-section .module-add-container .action-layer .material-icons:hover {
  opacity: 1;
}
.admin-skills-page .drag-n-drop .left-section .module-container {
  width: 100%;
}
.admin-skills-page .drag-n-drop .left-section .module-container .header {
  background-color: #fff;
  text-align: center;
}
.admin-skills-page .drag-n-drop .left-section .module-container .header span {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 30px;
}
.admin-skills-page .drag-n-drop .left-section .module-container .submodule-list {
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.2);
  list-style-type: none;
}
.admin-skills-page .drag-n-drop .left-section .module-container .submodule-list .submodule-container {
  margin: 0;
}
.admin-skills-page .drag-n-drop .left-section .module-container .submodule-list .submodule-container .skill-list {
  display: table;
  width: 100%;
  padding: 0 5px;
  list-style-type: none;
}
.admin-skills-page .drag-n-drop .left-section .module-container .submodule-list .submodule-container .skill-list .dndPlaceholder {
  float: left;
  background-color: #ddd !important;
  width: 100px;
  height: 100px;
  border-radius: 3px;
}
.admin-skills-page .drag-n-drop .left-section .module-container .submodule-list .submodule-container .skill-list .skill-item {
  float: left;
  width: 100px;
  margin: 5px 0 0 0;
  background-color: transparent;
  border-radius: 3px;
  font-size: 20px;
  text-align: center;
  color: #172429;
  opacity: 0.6;
}
.admin-skills-page .drag-n-drop .left-section .module-container .submodule-list .submodule-container .skill-list .skill-item img {
  width: 100px;
  border-radius: 3px;
  cursor: move;
}
.admin-skills-page .drag-n-drop .left-section .module-container .submodule-list .submodule-container .skill-list .skill-item .action-layer {
  display: none;
  position: absolute;
  margin: 5px;
  padding: 10px 5px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
}
.admin-skills-page .drag-n-drop .left-section .module-container .submodule-list .submodule-container .skill-list .skill-item .action-layer:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
.admin-skills-page .drag-n-drop .left-section .module-container .submodule-list .submodule-container .skill-list .skill-item .action-layer .material-icons {
  float: left;
  width: 30px;
  height: 30px;
  margin: 0 5px;
  color: #172429;
  font-size: 30px;
  cursor: pointer;
  opacity: 0.7;
}
.admin-skills-page .drag-n-drop .left-section .module-container .submodule-list .submodule-container .skill-list .skill-item .action-layer .material-icons:hover {
  opacity: 1;
}
.admin-skills-page .drag-n-drop .left-section .module-container .submodule-list .submodule-container .skill-list .skill-item:hover {
  opacity: 1;
}
.admin-skills-page .drag-n-drop .left-section .module-container .submodule-list .submodule-container .skill-list .skill-item:hover .action-layer {
  display: block;
}
.admin-skills-page .drag-n-drop .left-section .module-container .submodule-list .submodule-container .skill-list .skill-item.skill-add-placeholder {
  opacity: 1;
}
.admin-skills-page .drag-n-drop .left-section .module-container .submodule-list .submodule-container .skill-list .skill-item.skill-add-placeholder .action-layer {
  position: relative;
  display: block;
  width: 80px;
  height: 70px;
  margin: 9px auto;
  padding: 0;
  cursor: pointer;
}
.admin-skills-page .drag-n-drop .left-section .module-container .submodule-list .submodule-container .skill-list .skill-item.skill-add-placeholder .action-layer .material-icons {
  width: unset;
  height: unset;
  margin: unset;
  padding: 20px 26px;
  font-size: 30px;
}
.admin-skills-page .drag-n-drop .right-section {
  float: right;
  width: calc(100% - 120px);
}
.admin-skills-page .drag-n-drop .right-section .module-container {
  width: 100%;
  margin-bottom: 20px;
}
.admin-skills-page .drag-n-drop .right-section .module-container .header {
  background-color: #fff;
  text-align: center;
}
.admin-skills-page .drag-n-drop .right-section .module-container .header span {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 30px;
}
.admin-skills-page .drag-n-drop .right-section .module-container .header .material-icons {
  float: right;
  font-size: 30px;
  cursor: pointer;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list {
  display: table;
  width: 100%;
  padding: 0 5px 5px;
  background-color: rgba(255, 255, 255, 0.2);
  list-style-type: none;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .dndPlaceholder {
  background-color: #ddd !important;
  margin: 5px 0;
  width: 100%;
  height: 115px;
  border-radius: 3px;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container {
  min-width: 168px;
  margin: 5px 0 0 0;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container .header span {
  margin: 0;
  border: none;
  text-align: center;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 12px;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container .skill-list {
  display: table;
  width: 100%;
  min-height: 115px;
  padding: 0;
  list-style-type: none;
  border: 1px solid #fff;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container .skill-list .dndPlaceholder {
  float: left;
  background-color: #ddd !important;
  margin: 5px;
  width: 100px;
  height: 100px;
  border-radius: 3px;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container .skill-list .skill-item {
  float: left;
  width: 100px;
  height: 100px;
  margin: 5px;
  background-color: transparent;
  border: 1px solid #565656;
  border-radius: 3px;
  font-size: 20px;
  text-align: center;
  color: #172429;
  opacity: 0.6;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container .skill-list .skill-item img {
  width: 100px;
  border-radius: 3px;
  cursor: move;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container .skill-list .skill-item .action-layer {
  display: none;
  position: absolute;
  margin: 5px;
  padding: 10px 5px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container .skill-list .skill-item .action-layer:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container .skill-list .skill-item .action-layer .material-icons {
  float: left;
  width: 30px;
  height: 30px;
  margin: 0 5px;
  color: #172429;
  font-size: 30px;
  cursor: pointer;
  opacity: 0.7;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container .skill-list .skill-item .action-layer .material-icons:hover {
  opacity: 1;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container .skill-list .skill-item:hover {
  opacity: 1;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container .skill-list .skill-item:hover .action-layer {
  display: block;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container .skill-list .skill-item.skill-add-placeholder {
  opacity: 1;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container .skill-list .skill-item.skill-add-placeholder .action-layer {
  display: block;
  margin: 8px 9px;
  padding: 25px 20px;
  cursor: pointer;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container.submodule-add-placeholder {
  height: 115px;
  border: 1px solid #fff;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container.submodule-add-placeholder .header {
  padding: 0 2px;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container.submodule-add-placeholder .action-layer {
  width: 80px;
  margin: 9px auto 0;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container.submodule-add-placeholder .action-layer:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container.submodule-add-placeholder .action-layer .material-icons {
  padding: 22px 27px;
  color: #172429;
  font-size: 30px;
  cursor: pointer;
  opacity: 0.7;
}
.admin-skills-page .drag-n-drop .right-section .module-container .submodule-list .submodule-container.submodule-add-placeholder .action-layer .material-icons:hover {
  opacity: 1;
}
.contact-page {
  width: 100%;
}
.contact-page .contact-form-container {
  min-width: 300px;
  max-width: 480px;
  margin: 0 auto;
}
.contact-page .contact-form-container .header {
  margin: 20px 0 10px 0;
  padding: 2px 0;
  background-color: rgba(255, 255, 255, 0.05);
  color: #fff;
  border: 1px solid #565656;
  border-radius: 6px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
}
.contact-page .contact-form-container input {
  width: 100%;
  height: 24px;
  margin: 4px 0;
  padding: 0 5px;
  background-color: rgba(255, 255, 255, 0.05);
  line-height: 25px;
  font-size: 10px;
  color: #fff;
  border: 1px solid #565656;
  border-radius: 6px;
  outline: none;
  font-weight: 600;
}
.contact-page .contact-form-container input:focus {
  outline: none;
}
.contact-page .contact-form-container input::-webkit-input-placeholder {
  text-transform: uppercase;
}
.contact-page .contact-form-container input::-moz-placeholder {
  text-transform: uppercase;
}
.contact-page .contact-form-container input:-ms-input-placeholder {
  text-transform: uppercase;
}
.contact-page .contact-form-container input:-moz-placeholder {
  text-transform: uppercase;
}
.contact-page .contact-form-container textarea {
  width: 100%;
  min-height: 300px;
  max-width: 480px;
  margin: 4px 0;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.05);
  line-height: 14px;
  font-size: 12px;
  color: #fff;
  border: 1px solid #565656;
  border-radius: 6px;
  outline: none;
  font-weight: 600;
}
.contact-page .contact-form-container textarea:focus {
  outline: none;
}
.contact-page .contact-form-container .send-email-button {
  width: 46px;
  height: 46px;
  position: absolute;
  margin: -62px 0 0 426px;
  border: 1px solid #565656;
  border-radius: 6px;
  background-color: rgba(148, 16, 16, 0.9);
  cursor: pointer;
}
.contact-page .contact-form-container .send-email-button img {
  height: 32px;
  margin: 8px 0 0 7px;
}
.contact-page .contact-form-container .contact-form-footer {
  display: table;
  width: 100%;
}
.contact-page .contact-form-container .contact-form-footer .captcha-container {
  float: left;
  margin: 0;
  border-radius: 3px;
}
.contact-page .contact-form-container .contact-form-footer .owner-data-container {
  float: right;
  margin: 0;
}
.contact-page .contact-form-container .contact-form-footer .owner-data-container .owner-data-row {
  display: block;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
}
.contact-page .contact-form-container .alerts-container {
  display: table;
  width: 100%;
}
.contact-page .contact-form-container .alerts-container .alert-danger,
.contact-page .contact-form-container .alerts-container .alert-success {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  text-align: center;
  font-weight: 500;
}
.contact-page .contact-form-container .alerts-container .alert-danger .close,
.contact-page .contact-form-container .alerts-container .alert-success .close {
  top: unset;
  right: unset;
}
.contact-page .contact-form-container .alerts-container .alert-success {
  color: #3c763d;
}
.about-page {
  width: 100%;
}
.about-page .about-container {
  width: 1000px;
  height: calc(100vh - 125px);
  margin: 0 auto;
  background: url("/img/about-me.png") left top no-repeat;
  background-position: 0 40px;
  background-size: 40vh auto;
}
.about-page .about-container .about-data-container {
  float: right;
  width: 580px;
  height: calc(100vh - 130px);
  padding: 20px 10px;
  overflow: auto;
  color: #fff;
}
.about-page .about-container .about-data-container .owner-name {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 400;
}
.about-page .about-container .about-data-container .owner-positions {
  font-size: 13px;
  font-weight: 400;
}
.about-page .about-container .about-data-container .owner-description {
  margin: 20px 0;
  font-size: 12px;
}
.about-page .about-container .about-data-container .about-thumbnail-list {
  width: 100%;
}
.about-page .about-container .about-data-container .about-thumbnail-list .about-thumbnail-image {
  float: left;
  width: 33.3%;
  margin: 0;
}
.about-page .about-container .about-data-container .about-thumbnail-list .about-thumbnail-image img {
  width: 94%;
  margin: 0 2%;
  filter: grayscale(100%);
  cursor: pointer;
}
.about-page .about-container .about-data-container .about-thumbnail-list .about-thumbnail-image img:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
}
@media (max-width: 1524px) {
  .modal-dialog.modal-item-preview .modal-content .item-container {
    width: 100%;
  }
}
@media (max-width: 1400px) {
  .content-section {
    padding: 10px;
  }
  .content-section .skills-page .module-container .submodule-container {
    width: 32.35%;
    margin: 0.5%;
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-data-container {
    width: calc(100vw - 1024px);
    margin-top: 0;
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-description {
    max-height: calc(100vh - 200px);
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-data-container.graphic .item-description {
    max-height: calc(100vh - 150px);
  }
}
@media (max-width: 1280px) {
  .modal-dialog.modal-item-preview {
    height: 100vh;
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-image-container {
    width: 100%;
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-image-container .image {
    width: 100%;
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-image-container .image img {
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-image-container .image .fa-spinner {
    top: 0;
    left: calc(50vw - 70px);
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-image-container.graphic {
    height: unset;
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-image-container.graphic .image {
    overflow: initial;
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-video-container {
    width: 100%;
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-video-container .video {
    width: 100%;
    text-align: center;
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-video-container .video img {
    position: initial;
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-video-container .video-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-data-container {
    width: 100%;
    max-width: 1000px;
    height: initial;
    margin: 0 auto;
    float: initial;
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-dismiss-button {
    right: 10px;
    margin-top: 0;
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-description {
    max-height: unset;
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-thumbnail-list {
    position: relative;
    background: none;
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-thumbnail-list .thumbnail {
    margin: 0 4px 4px 0;
  }
  .modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-thumbnail-list .thumbnail img {
    width: 80px;
  }
  .content-section {
    height: calc(100vh - 80px);
    padding: 0;
  }
  .content-section .about-page .about-container {
    width: 880px;
  }
  .content-section .about-page .about-container .about-data-container {
    width: 505px;
  }
  .content-section .clients-page {
    padding: 0 10px;
  }
  .content-section .list-page {
    padding: 10px;
  }
  .content-section .list-page .list-header {
    margin: 10px 10px 0;
  }
  .content-section .list-page .subcategories-toggler img {
    right: 30px;
  }
  .content-section .list-page .subcategories-container {
    width: calc(100% - 20px);
    margin: 20px auto;
  }
  .content-section .list-page .list-container .list-item {
    width: calc(33.3% - 20px);
    height: unset;
    margin: 10px;
  }
  .content-section .list-page.showreel .list-container {
    width: 100%;
  }
  .content-section .list-page.showreel .list-container .list-item {
    width: 100%;
    height: unset;
  }
  .content-section .contact-page {
    padding: 10px;
  }
}
@media (max-width: 1080px) {
  .content-section .about-page .about-container .about-data-container {
    width: 505px;
  }
}
@media (max-width: 1050px) {
  .modal .modal-dialog.modal-item-preview .modal-content .item-container .item-data-container {
    padding: 0 10px;
  }
  .main-container .content-section {
    padding: 0;
  }
  .main-container .content-section .about-page {
    margin-top: 50px;
  }
  .main-container .content-section .about-page .about-container {
    width: 100%;
    padding: 20px;
    background-position: center top;
  }
  .main-container .content-section .about-page .about-container .about-data-container {
    width: 100%;
    height: unset;
    overflow: hidden;
    padding: 520px 0 0;
  }
  .main-container .content-section .about-page .about-container .about-data-container .owner-name,
  .main-container .content-section .about-page .about-container .about-data-container .owner-positions {
    text-align: center;
  }
  .main-container .content-section .about-page .about-container .about-data-container .about-thumbnail-list {
    position: initial;
    margin: 0 auto;
  }
  .main-container .content-section .list-page .list-container .list-item.admin-view {
    height: unset;
  }
  .main-container .content-section .list-page .list-container .list-item.admin-view.video {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 800px) {
  .modal {
    top: 50px;
  }
  .modal .modal-dialog.modal-item-preview {
    height: calc(100vh - 50px);
  }
  .modal .modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-thumbnail-list {
    padding: 0;
  }
  .modal .modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-thumbnail-list .thumbnail {
    margin: 1vw;
  }
  .modal .modal-dialog.modal-item-preview .modal-content .item-container .item-data-container .item-thumbnail-list .thumbnail img {
    width: 22vw;
  }
  .modal-backdrop {
    top: 50px;
  }
}
@media screen and (max-width: 800px), screen and (max-height: 728px) {
  .main-container .categories-section {
    width: 85px;
  }
  .main-container .categories-section .list .item {
    padding: 0 0 8px;
  }
  .main-container .categories-section .list .item .title {
    font-size: 9px;
  }
  .main-container .content-section {
    margin: 50px 0 0 95px;
  }
  .main-container .content-section .skills-page .module-container .submodule-container {
    width: calc(50% - 10px);
    margin: 0 5px;
  }
}
@media screen and (max-width: 556px) {
  .content-section .about-page .about-container .about-data-container .about-thumbnail-list .about-thumbnail-image {
    width: 50%;
    margin: 10px 0;
    text-align: center;
  }
  .content-section .about-page .about-container .about-data-container .about-thumbnail-list .about-thumbnail-image img {
    width: 90%;
  }
}
@media screen and (max-width: 420px), screen and (max-height: 768px) {
  body .start-layer-text .text-container span.text-big {
    font-size: 36px;
    text-align: center;
  }
  body .start-layer-form .choose-container {
    top: 130px;
  }
  body .start-layer-form .form-container {
    top: 165px;
    text-align: center;
  }
  body .start-layer-form .form-container img {
    float: unset;
  }
  body .start-layer-form .form-container input {
    display: table;
    width: 200px;
    margin: 2px auto;
  }
}
@media screen and (max-width: 720px) {
  .main-container .categories-section {
    display: none;
  }
  .main-container .content-section {
    width: 100%;
    margin: 50px 0 0;
  }
  .main-container .content-section .skills-page {
    padding: 0 5px;
  }
  .main-container .content-section .list-page .list-container .list-item {
    width: calc(50% - 20px);
    margin: 10px;
  }
  .main-container .content-section .list-page.showreel .list-container {
    width: 100%;
  }
  .main-container .content-section .list-page.showreel .list-container .list-item {
    width: calc(100% - 20px);
    min-height: 450px;
  }
}
@media screen and (max-width: 580px) {
  .main-container .content-section .clients-page .clients-list-container {
    padding: 10px;
  }
  .main-container .content-section .clients-page .clients-list-container .client-list-item {
    width: calc(50% - 8px);
    margin: 4px;
  }
  .main-container .content-section .clients-page .clients-list-container .client-list-item img {
    width: 100%;
  }
  .main-container .content-section .skills-page .module-container .submodule-container {
    width: calc(100% - 10px);
    margin-bottom: 10px;
  }
  .main-container .content-section .list-page .list-container .list-item {
    width: calc(100% - 20px);
  }
  .main-container .content-section .list-page .list-container .list-item.admin-view.video {
    margin-bottom: 5px;
  }
  .main-container .content-section .list-page.showreel .list-container {
    width: 100%;
  }
  .main-container .content-section .list-page.showreel .list-container .list-item {
    width: calc(100% - 20px);
  }
  .main-container .content-section .list-page .subcategories-toggler {
    margin: 0 0 20px;
    padding: 2px 0;
    background-color: transparent;
    color: #fff;
    border: 1px solid #565656;
    border-radius: 6px;
    text-transform: uppercase;
    text-align: center;
  }
  .main-container .content-section .list-page .subcategories-toggler img {
    position: absolute;
    width: 20px;
  }
  .main-container .content-section .list-page .subcategories-container .subcategory-item {
    width: calc(50% - 10px);
  }
  .main-container .content-section .contact-page .contact-form-container .contact-form-footer .owner-data-container {
    margin: 10px 0;
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 480px) {
  .main-container .content-section .contact-page .contact-form-container .send-email-button {
    margin: -65px 0 0;
    right: 20px;
  }
}
